import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import colaboradores from './colaboradores';
import tipos_colaboradores from './tipos_colaboradores';
import punto from './punto';
import ventas from './ventas';
import clientes from './clientes';
import ordenes from './ordenes';
import gastos from './gastos';
import proveedores from './proveedores';
import inventario from './inventario';
import movimientos from './movimientos';
import solicitudes from './solicitudes';
import calendario from './calendario';
import configuracion from './configuracion';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, colaboradores, tipos_colaboradores, punto, ventas, clientes, ordenes, gastos, proveedores, inventario, movimientos, solicitudes, calendario, configuracion
});