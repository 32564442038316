import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { buscarClientes } from '../../../actions/clientes';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';
import { buscarVentas, filtrarVentas } from '../../../actions/ventas';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const FiltrosVenta = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.ventas);
    const { listado: listado_clientes } = useSelector(state => state.clientes);
    const { listado: listado_metodos } = useSelector(state => state.configuracion.metodos);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        concepto: null,
        cliente: null,
        metodo: null, 
        fecha_inicio: null,
        fecha_fin: null
    });

    const [clienteData, setClienteData] = useState(null);
    const [clientesFilter, setClientesFilter] = useState([]);

    const [metodoData, setMetodoData] = useState(null);
    const [metodosFilter, setMetodosFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Filtros */
    const handleAbrir = () => {
        dispatch(buscarClientes({
            nombre: null
        }));
        dispatch(buscarMetodosConfiguracion());
    }

    const handleSelectCliente = (option) => {
        setFiltrosData({
            ...filtrosData,
            cliente: option ? option.value : null
        });

        setClienteData(option);
    }

    const handleSelectMetodo = (option) => {
        setFiltrosData({
            ...filtrosData,
            metodo: option ? option.value : null
        });

        setMetodoData(option);
    }

    const handleDatePicker = (name, value) => {
        setFiltrosData({
            ...filtrosData,
            [name]: value
        });
    }
    
    const handleProcesar = async (limpiar) => {
        await setStatusProcesar(true);

        if(!limpiar){
            await dispatch(buscarVentas(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                concepto: null,
                cliente: null,
                metodo: null, 
                fecha_inicio: null,
                fecha_fin: null
            };

            await dispatch(buscarVentas(datos_base));

            await setFiltrosData(datos_base);

            await setClienteData(null);
            await setMetodoData(null);
        }

        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtrarVentas(false));
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_clientes?.length; i++) {
            options.push({ value: listado_clientes[i].id, label: listado_clientes[i].nombre + ' ' + listado_clientes[i].apellido });
        }

        setClientesFilter(options);
    }, [listado_clientes]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_metodos.length; i++) {
            options.push({ value: listado_metodos[i].id, label: listado_metodos[i].nombre });
        }

        setMetodosFilter(options);
    }, [listado_metodos]);

    return (
        <Modal show={ventana_filtros} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar ventas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="cliente"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Cliente"
                                options={clientesFilter}
                                value={clienteData}
                                isClearable={true}
                                onChange={(value) => handleSelectCliente(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="metodo"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Metodo de pago"
                                options={metodosFilter}
                                value={metodoData}
                                isClearable={true}
                                onChange={(value) => handleSelectMetodo(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-4">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_inicio"
                                    name="fecha_inicio"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ filtrosData.fecha_inicio ? new Date(filtrosData.fecha_inicio) : '' }
                                    onChange={ date => handleDatePicker('fecha_inicio', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_inicio">Fecha inicial</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="fecha_fin"
                                    name="fecha_fin"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ filtrosData.fecha_fin ? new Date(filtrosData.fecha_fin) : '' }
                                    onChange={ date => handleDatePicker('fecha_fin', date) }
                                />
                                <label className="label-datepicker" htmlFor="fecha_fin">Fecha final</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-secondary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => handleProcesar(true)}><i className="fas fa-trash fa-sm"></i> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosVenta;