import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarGastos, filtrarGastos } from '../../../actions/gastos';

import { formatNumber } from '../../../utils/generalFunctions';

import FiltrosGasto from './FiltrosGasto';
import NuevoGasto from './NuevoGasto';
import EditarGasto from './EditarGasto';
import EliminarGasto from './EliminarGasto';

const Gastos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Gastos',
            path: '/egresos/gastos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.gastos);

    const [gastos, setGastos] = useState([]);

    const [gastoAgregar, setGastoAgregar] = useState(null);
    const [gastoEditar, setGastoEditar] = useState(null);
    const [gastoEliminar, setGastoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Concepto',
            selector: row => row.concepto,
            grow: 4
        },
        {
            name: 'Proveedor',
            selector: row => row.proveedor?.alias || 'Sin definir',
            grow: 2
        },
        {
            name: 'Método de pago',
            selector: row => row.metodo?.nombre || 'Sin definir',
            grow: 1.5
        },
        {
            name: 'Fecha',
            grow: 2,
            cell: item => item.fecha && item.fecha != '0000-00-00' ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'
        },
        {
            name: 'Monto',
            selector: row => formatNumber(row.monto, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarGasto(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarGasto(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarGastos(true));
    }

    /* Crear gasto */
    const handleAgregarGasto = (gasto) => {
        setGastoEditar(null);
        setGastoEliminar(null);

        setGastoAgregar(gasto);        
    }

    /* Editar gasto */
    const handleEditarGasto = (gasto) => {
        setGastoAgregar(null);
        setGastoEliminar(null);

        setGastoEditar(gasto);
    }

    /* Eliminar gasto */
    const handleEliminarGasto = (gasto) => {
        setGastoAgregar(null);
        setGastoEditar(null);

        setGastoEliminar(gasto);
    }

    useEffect(() => {
        dispatch(buscarGastos({
            concepto: null, 
            proveedor: null, 
            metodo: null, 
            fecha_inicio: null, 
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setGastos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosGasto />
            <NuevoGasto gasto={gastoAgregar} reestablecerGasto={handleAgregarGasto} />
            <EditarGasto gasto={gastoEditar} reestablecerGasto={handleEditarGasto} />
            <EliminarGasto gasto={gastoEliminar} reestablecerGasto={handleEliminarGasto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Gastos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarGasto({ })}>Agregar gasto</button>
                    </div>

                    <div className="mt-3">
                        <DataTable 
                            columns={columns}
                            data={gastos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Gastos;