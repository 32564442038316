import { INVENTARIO } from '../actions/types';

const initialState = {
    listado: [],
    detalles: [], 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case INVENTARIO.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case INVENTARIO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case INVENTARIO.DETALLES:
            return {
                ...state,
                detalles: payload
            };
        
        default:
            return state;
    }
}