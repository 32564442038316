import axios from 'axios';

import { GASTOS } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const filtrarGastos = (opcion) => dispatch => {
    dispatch({
        type: GASTOS.FILTROS,
        payload: opcion
    });
}

export const buscarGastos = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gastos/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GASTOS.BUSCAR,
            payload: res.data.gastos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerGasto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gastos/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GASTOS.DETALLES,
            payload: res.data.gasto
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarGasto = () => dispatch => {
    dispatch({
        type: GASTOS.DETALLES,
        payload: null
    });
}

export const crearGasto = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(gasto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gastos/crear`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GASTOS.AGREGAR,
            payload: res.data.gasto
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const editarGasto = (gasto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(gasto);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gastos/editar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GASTOS.EDITAR,
            payload: res.data.gasto
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarGasto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/gastos/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: GASTOS.ELIMINAR,
            payload: id
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}