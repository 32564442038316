import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarInventario, filtarProductos } from '../../../actions/inventario';

import { formatNumber } from '../../../utils/generalFunctions';

import FiltrosProducto from './FiltrosProducto';
import DetallesProducto from './DetallesProducto';

const Inventario = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Inventario',
            path: '/inventario'
        }
    ];

    const dispatch = useDispatch();

    const { listado } = useSelector(state => state.inventario);
    
    const [inventario, setInventario] = useState([]);

    const [productoDetalles, setProductoDetalles] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Categoría',
            selector: row => row.categoria.nombre || 'Sin definir'
        },
        {
            name: 'Subcategoría',
            selector: row => row.subcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Subsubcategoría',
            selector: row => row.subsubcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Código de barras',
            selector: row => row.codigo_barras
        },
        {
            name: 'Reorden',
            selector: row => formatNumber(row.reorden, 0, '', '')
        },
        {
            name: 'Cantidad actual',
            cell: row => {
                return(
                    <div className="w-100 text-end">
                        <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleDetallesProducto(row)}><Badge bg={row.cantidad > row.reorden ? 'success' : (row.cantidad < row.reorden ? 'danger' : 'warning')}>{row.cantidad}</Badge></button></h5>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarProductos(true));
    }

    /* Detalles producto */
    const handleDetallesProducto = (producto) => {
        setProductoDetalles(producto);
    }

    useEffect(() => {
        dispatch(buscarInventario({
            nombre: null, 
            categoria: null, 
            subcategoria: null, 
            subsubcategoria: null, 
            codigo_barras: null, 
            status: 0
        }));
    }, []);

    useEffect(() => {
        setInventario(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosProducto/>
            <DetallesProducto producto={productoDetalles} reestablecerProducto={handleDetallesProducto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Inventario de productos</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                    </div>

                    <div className="mt-3">
                        <DataTable 
                            columns={columns}
                            data={inventario}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Inventario;