import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import DataTable from 'react-data-table-component';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { buscarProveedores } from '../../../actions/proveedores';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';
import { obtenerOrden, aceptarOrden } from '../../../actions/ordenes';

import { formatNumber } from '../../../utils/generalFunctions';

import UbicacionesProducto from './UbicacionesProducto';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const AceptarOrden = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Ordenes',
            path: '/egresos/ordenes'
        },
        {
            activo: true,
            nombre: 'Aceptar orden',
            path: '/egresos/ordenes/aceptar/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { detalles } = useSelector(state => state.ordenes);
    const { listado: listado_proveedores } = useSelector(state => state.proveedores);
    const { listado: listado_metodos } = useSelector(state => state.configuracion.metodos);

    const { id } = useParams();

    const [ordenData, setOrdenData] = useState({
        id: null,
        id_proveedor: null,
        id_metodo: null,
        monto_calculado: 0, 
        monto_real: 0, 
        productos: []
    });

    const [proveedorData, setProveedorData] = useState(null);
    const [proveedoresFilter, setProveedoresFilter] = useState([]);

    const [metodoData, setMetodoData] = useState(null);
    const [metodosFilter, setMetodosFilter] = useState([]);

    const [productosRecibir, setProductosRecibir] = useState([]);

    const [unidadesData, setUnidadesData] = useState([]);

    const [posicionProducto, setPosicionProducto] = useState(null);
    const [productoUbicaciones, setProductoUbicaciones] = useState(null);

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Costo',
            selector: row => formatNumber(row.precio_compra, 2, '$ ', '')
        }, 
        {
            name: 'Cantidad a recibir',
            cell: (row, index) => {
                return(
                    <input type="number" className="form-control" value={ordenData?.productos.length > 0 ? ordenData?.productos[index]?.cantidad_surtir : ''} onChange={e => handleCantidadChange(e, index)}/>
                )
            }
        }, 
        {
            name: 'Unidad',
            grow: 1.5,
            cell: (row, index) => {
                return(
                    <div className="input-group h-100 py-2">
                        <Select
                            className="select-group"
                            classNamePrefix="react-select"
                            placeholder="Inventario"
                            options={row.unidades}
                            value={unidadesData[index]}
                            isClearable={true}
                            onChange={(value) => handleSelectUnidad(value, index)}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            components={{
                                ValueContainer: CustomValueContainer
                            }}
                            styles={{ ...styles_select }}
                        />
                    </div>
                )
            }
        }, 
        {
            name: 'Cantidad sin ubicación',
            cell: (row, index) => {
                return(
                    <div className="w-100 text-end">
                        <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleUbicacionesProducto(row, index)}><Badge bg={(row.cantidad_sin_asignar - row.cantidad_asignada) == 0 ? 'success' : 'warning'}>{(row.cantidad_sin_asignar - row.cantidad_asignada)}</Badge></button></h5>
                    </div>
                )
            }
        }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Editar orden */
    const handleSelectProveedor = (option) => {
        setOrdenData({
            ...ordenData,
            id_proveedor: option ? option.value : null
        });

        setProveedorData(option);
    }

    const handleSelectMetodo = (option) => {
        setOrdenData({
            ...ordenData,
            id_metodo: option ? option.value : null
        });

        setMetodoData(option);
    }

    const handleNumberChange = (value) => {
        setOrdenData({
            ...ordenData,
            monto_real: value || ''
        });
    }

    const handleCantidadChange = ({ target }, posicion) => {        
        let productos = ordenData.productos;
        let monto_calculado = 0;
        
        productos[posicion].cantidad_surtir = target.value || '';
        productos[posicion].cantidad_nueva = target.value ? ((productos[posicion].cantidad_actual * productos[posicion].conversion) + parseFloat(target.value)) : (productos[posicion].cantidad_actual * productos[posicion].conversion);
        productos[posicion].ubicaciones = [];
        productos[posicion].cantidad_sin_asignar = target.value || 0;
        productos[posicion].cantidad_asignada = 0;
        productos[posicion].areas_selects = [];
        productos[posicion].organizadores_selects = [];
        productos[posicion].ubicaciones_selects = [];

        for (var i = 0; i < productosRecibir.length; i++) {
            monto_calculado += parseFloat(productos[i].cantidad_surtir) * productosRecibir[i].precio_compra;
        }

        setOrdenData({
            ...ordenData,
            productos, 
            monto_calculado
        });
    }

    const handleSelectUnidad = (option, posicion) => {
        let productos = ordenData.productos;
        let unidades = unidadesData;
        let monto_calculado = 0;

        unidades[posicion] = option;

        productos[posicion].unidad = option ? option.value : null;
        productos[posicion].conversion = option ? option.cantidad : null;
        productos[posicion].cantidad_nueva = option ? ((productos[posicion].cantidad_actual * option.cantidad) + parseFloat(productos[posicion].cantidad_nueva)) : (productos[posicion].cantidad_actual + parseFloat(productos[posicion].cantidad_nueva));

        for (var i = 0; i < productosRecibir.length; i++) {
            monto_calculado += parseFloat(productos[i].cantidad_surtir) * productosRecibir[i].precio_compra;
        }

        setUnidadesData(unidades);

        setOrdenData({
            ...ordenData,
            productos, 
            monto_calculado
        });
    }

    const handleUbicacionesProducto = (producto, posicion) => {
        setPosicionProducto(posicion);
        setProductoUbicaciones(producto);
    }

    const handleActualizarProducto = (producto, posicion) => {
        let productos = ordenData.productos;

        productos[posicion] = producto;
    }

    const handleGuardarOrden = async () => {
        if(ordenData.productos.length > 0){
            let productos_sin_asignar = 0;

            for (var i = 0; i < ordenData.productos.length; i++) {
                productos_sin_asignar += ordenData.productos[i].cantidad_sin_asignar - ordenData.productos[i].cantidad_asignada;
            }

            if(productos_sin_asignar == 0){
                await dispatch(aceptarOrden(ordenData));

                await navigate("/egresos/ordenes");
            }else{
                await dispatch(setAlert('Todos los productos deben de tener asignación de inventario', 'danger'));
            }
        }else{
            await dispatch(setAlert('Debe de haber al menos un producto', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(buscarProveedores());
        dispatch(buscarMetodosConfiguracion());
    }, []);

    useEffect(() => {
        if(id){
            dispatch(obtenerOrden(id));

            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editarBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setOrdenData({
                ...ordenData,
                id: detalles?.id,
                id_proveedor: detalles?.proveedor?.id,
                id_metodo: detalles?.metodo?.id,
                monto_calculado: detalles?.monto_calculado, 
                monto_real: detalles?.monto,
                productos: detalles?.productos
            });

            setProductosRecibir(detalles?.productos);
            setUnidadesData(detalles?.unidades);

            let proveedor_elegido = null;

            if(detalles?.proveedor?.id){
                proveedor_elegido = {
                    value: detalles?.proveedor?.id, 
                    label: detalles?.proveedor?.alias ? detalles?.proveedor?.alias + ' (' + detalles?.proveedor?.razon_social + ')' : detalles?.proveedor?.razon_social
                };
            }

            setProveedorData(proveedor_elegido);

            let metodo_elegido = null;

            if(detalles?.metodo?.id){
                metodo_elegido = {
                    value: detalles?.metodo?.id, 
                    label: detalles?.metodo?.nombre
                };
            }

            setMetodoData(metodo_elegido);
        }
    }, [detalles]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_proveedores.length; i++) {
            options.push({ value: listado_proveedores[i].id, label: listado_proveedores[i].alias ? listado_proveedores[i].alias + ' (' + listado_proveedores[i].razon_social + ')' : listado_proveedores[i].razon_social });
        }

        setProveedoresFilter(options);
    }, [listado_proveedores]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_metodos.length; i++) {
            options.push({ value: listado_metodos[i].id, label: listado_metodos[i].nombre });
        }

        setMetodosFilter(options);
    }, [listado_metodos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>

            <UbicacionesProducto producto={productoUbicaciones} posicion={posicionProducto} reestablecerProducto={handleUbicacionesProducto} actualizarProducto={handleActualizarProducto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Aceptar órden</h3>

                    <div className="row mb-3">
                        <div className="col-md-5 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_proveedor"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Proveedor"
                                    options={proveedoresFilter}
                                    value={proveedorData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProveedor(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_metodo"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Método de pago"
                                    options={metodosFilter}
                                    value={metodoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectMetodo(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input
                                        id="monto_calculado"
                                        name="monto_calculado"
                                        type="text" 
                                        className="form-control text-center"
                                        value={formatNumber(ordenData.monto_calculado, 2, '', '')}
                                        disabled
                                    />
                                    <label htmlFor="monto_calculado">Monto calculado</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="monto_real"
                                        name="monto_real"
                                        className="form-control text-center"
                                        value={ordenData.monto_real || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value) => handleNumberChange(value)}
                                    />
                                    <label htmlFor="monto_real">Monto real</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="mt-5" />

                    <div className="row mb-3">
                        <h5>Productos a recibir</h5>

                        <DataTable 
                            columns={columns}
                            data={productosRecibir}
                            highlightOnHover={true}
                        />

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarOrden}>Guardar</button>
                            <Link to='/egresos/ordenes'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AceptarOrden;