import { ORDENES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case ORDENES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case ORDENES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case ORDENES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case ORDENES.CANCELAR:
            return {
                ...state,
                listado: state.listado.map(
                    orden => orden.id == payload.id ? payload : orden
                )
            };

        case ORDENES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( orden => orden.id !== payload )
            };
        
        default:
            return state;
    }
}