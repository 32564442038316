import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { buscarInventario } from '../../../actions/inventario';
import { buscarEventos } from '../../../actions/solicitudes';
import { crearSolicitud } from '../../../actions/solicitudes';
import { filtarProductos } from '../../../actions/ordenes';

import FiltrosProducto from './FiltrosProducto';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevaSolicitud = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Solicitudes',
            path: '/solicitudes'
        },
        {
            activo: true,
            nombre: 'Nueva solicitud',
            path: '/solicitudes/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado_eventos } = useSelector(state => state.solicitudes);
    const { listado: listado_productos } = useSelector(state => state.inventario);

    const [solicitudData, setSolicitudData] = useState({
        id_evento: null,
        productos: []
    });

    const [eventoData, setEventoData] = useState(null);
    const [eventosFilter, setEventosFilter] = useState([]);

    const [productosSolicitar, setProductosSolicitar] = useState([]);
    const [productosTotales, setProductosTotales] = useState([]);

    const [unidadesData, setUnidadesData] = useState([]);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columnsAdd = [
        {
            name: '',
            grow: 0,
            cell: (row, index) => {
                return(
                    <div className="form-check" key={'producto-' + row.id}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={'producto-' + row.id}
                            onChange={e => handleCheckboxRemove(e, index)}
                        />
                    </div>
                )
            }
        }, 
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 4
        },
        {
            name: 'Cantidad actual',
            cell: row => {
                return(
                    <div className="w-100 text-end">
                        <h5><Badge bg={row.cantidad > row.reorden ? 'success' : (row.cantidad < row.reorden ? 'danger' : 'warning')}>{row.cantidad}</Badge></h5>
                    </div>
                )
            }
        }, 
        {
            name: 'Cantidad a solicitar',
            cell: (row, index) => {
                return(
                    <input type="number" className="form-control" value={solicitudData.productos[index]?.cantidad_solicitar} onChange={e => handleCantidadChange(e, index)}/>
                )
            }
        }, 
        {
            name: 'Unidad',
            grow: 1.5,
            cell: (row, index) => {
                return(
                    <div className="input-group h-100 py-2">
                        <Select
                            className="select-group"
                            classNamePrefix="react-select"
                            placeholder="Inventario"
                            options={row.unidades}
                            value={unidadesData[index]}
                            isClearable={true}
                            onChange={(value) => handleSelectUnidad(value, index)}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            components={{
                                ValueContainer: CustomValueContainer
                            }}
                            styles={{ ...styles_select }}
                        />
                    </div>
                )
            }
        }
    ];

    const columnsAll = [
        {
            name: '',
            grow: 0,
            cell: row => {
                return(
                    <div className="form-check" key={'producto-' + row.id}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={'producto-' + row.id}
                            onChange={e => handleCheckboxAdd(e)}
                        />
                    </div>
                )
            }
        }, 
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Categoría',
            selector: row => row.categoria.nombre || 'Sin definir'
        },
        {
            name: 'Subcategoría',
            selector: row => row.subcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Subsubcategoría',
            selector: row => row.subsubcategoria.nombre || 'Sin definir'
        },
        {
            name: 'Cantidad actual',
            cell: row => {
                return(
                    <div className="w-100 text-end">
                        <h5><Badge bg={row.cantidad > row.reorden ? 'success' : (row.cantidad < row.reorden ? 'danger' : 'warning')}>{row.cantidad}</Badge></h5>
                    </div>
                )
            }
        }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nueva solicitud */
    const handleSelectEvento = (option) => {
        setSolicitudData({
            ...solicitudData,
            id_evento: option ? option.value : null
        });

        setEventoData(option);
    }

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarProductos(true));
    }

    /* Agregar producto */
    const handleCheckboxAdd = ({ target }) => {
        const productoId = target.id.split('-')[1];

        let producto_solicitar = productosTotales.find(producto => producto.id == productoId);

        if (producto_solicitar) {
            let producto_existente = productosSolicitar.find(producto => producto.id == producto_solicitar.id);

            setProductosTotales(productosTotales.filter(producto => producto.id != productoId));

            if(!producto_existente){

                setProductosSolicitar([
                    ...productosSolicitar, 
                    producto_solicitar
                ]);

                let productos = solicitudData.productos;
                let unidades = unidadesData;

                productos.push({
                    id: productoId, 
                    unidad: null, 
                    cantidad_actual: producto_solicitar.cantidad,
                    cantidad_solicitar: 0
                });

                unidades.push(null);

                setSolicitudData({
                    ...solicitudData, 
                    productos
                });

                setUnidadesData(unidades);
            }else{
                dispatch(setAlert('Ya tienes este producto en el listado a solicitar', 'danger'));
            }
        }
    }

    const handleCantidadChange = ({ target }, posicion) => {        
        let productos = solicitudData.productos;
        
        productos[posicion].cantidad_solicitar = target.value || '';

        setSolicitudData({
            ...solicitudData,
            productos
        });
    }

    const handleSelectUnidad = (option, posicion) => {
        let productos = solicitudData.productos;
        let unidades = unidadesData;

        unidades[posicion] = option;

        productos[posicion].unidad = option ? option.value : null;

        setUnidadesData(unidades);

        setSolicitudData({
            ...solicitudData,
            productos
        });
    }

    /* Remover producto */
    const handleCheckboxRemove = ({ target }, posicion) => {
        const productoId = target.id.split('-')[1];

        let producto_eliminar = productosSolicitar.find(producto => producto.id == productoId);

        if (producto_eliminar) {
            let producto_existente = productosTotales.find(producto => producto.id == producto_eliminar.id);
            let productos_solicitar = productosSolicitar.filter(producto => producto.id != productoId);

            setProductosSolicitar(productos_solicitar);

            if(!producto_existente){
                let productos_totales = [
                    ...productosTotales, 
                    producto_eliminar
                ];

                productos_totales = productos_totales.sort(function (a, b) {
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    return 0;
                });

                setProductosTotales(productos_totales);
            }else{
                dispatch(setAlert('Ya tienes este producto en el listado total', 'danger'));
            }

            let productos = solicitudData.productos;
            let unidades = unidadesData;

            productos.splice(posicion, 1);
            unidades.splice(posicion, 1);

            setSolicitudData({
                ...solicitudData, 
                productos
            });

            setUnidadesData(unidades);
        }
    }

    const handleGuardarSolicitud = async () => {
        if(solicitudData.productos.length > 0){
            await dispatch(crearSolicitud(solicitudData));

            await navigate("/solicitudes");
        }else{
            await dispatch(setAlert('Debe de haber al menos un producto', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(buscarEventos());
        dispatch(buscarInventario({
            nombre: null, 
            categoria: null, 
            subcategoria: null, 
            subsubcategoria: null, 
            codigo_barras: null, 
            status: 0
        }));
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_eventos.length; i++) {
            options.push({ value: listado_eventos[i].id, label: listado_eventos[i].id + ' (' + listado_eventos[i].paciente?.nombre + ' - ' + listado_eventos[i].espacio?.nombre + ') el ' + new Date(listado_eventos[i].fecha_ingreso).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(listado_eventos[i].fecha_ingreso).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) });
        }

        setEventosFilter(options);
    }, [listado_eventos]);

    useEffect(() => {
        setProductosTotales(listado_productos);
    }, [listado_productos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>

            <FiltrosProducto />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nueva solicitud</h3>

                    <div className="row mb-3">
                        <div className="col-md-5 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_evento"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Evento a surtir"
                                    options={eventosFilter}
                                    value={eventoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectEvento(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>
                    </div>

                    <hr className="mt-5" />

                    <div className="row mb-3">
                        <h5>Productos a solicitar</h5>

                        <DataTable 
                            columns={columnsAdd}
                            data={productosSolicitar}
                            highlightOnHover={true}
                        />

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarSolicitud}>Guardar</button>
                            <Link to='/solicitudes'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>

                    <hr className="mt-5" />

                    <div className="row mb-3">
                        <h5>Productos totales</h5>

                        <div className="d-flex justify-content-end mb-3">
                            <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        </div>

                        <DataTable 
                            columns={columnsAll}
                            data={productosTotales}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevaSolicitud;