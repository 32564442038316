import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../../actions/alert';
import { limpiarGasto, obtenerGasto, editarGasto } from '../../../actions/gastos';
import { buscarProveedores } from '../../../actions/proveedores';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const EditarGasto = ({ gasto, reestablecerGasto }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.gastos);
    const { listado: listado_proveedores } = useSelector(state => state.proveedores);
    const { listado: listado_metodos } = useSelector(state => state.configuracion.metodos);

    const [editarModal, setEditarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [gastoData, setGastoData] = useState({
        id: null,
        concepto: '', 
        id_proveedor: null,  
        id_metodo: null,  
        monto: 0
    });

    const [proveedorData, setProveedorData] = useState(null);
    const [proveedoresFilter, setProveedoresFilter] = useState([]);

    const [metodoData, setMetodoData] = useState(null);
    const [metodosFilter, setMetodosFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Editar gasto */
    const handleAbrir = () => {
        dispatch(limpiarGasto());
        dispatch(buscarProveedores());
        dispatch(buscarMetodosConfiguracion());
    }

    const handleInputChange = ({ target }) => {
        setGastoData({
            ...gastoData,
            [target.name]: target.value
        });
    }

    const handleSelectProveedor = (option) => {
        setGastoData({
            ...gastoData,
            id_proveedor: option ? option.value : null
        });

        setProveedorData(option);
    }

    const handleSelectMetodo = (option) => {
        setGastoData({
            ...gastoData,
            id_metodo: option ? option.value : null
        });

        setMetodoData(option);
    }
    
    const handleNumberChange = (value) => {
        setGastoData({
            ...gastoData,
            monto: value || ''
        });
    }
    
    const handleProcesar = async () => {
        if(gastoData.concepto && gastoData.monto){
            await setStatusProcesar(true);
            await dispatch(editarGasto(gastoData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('El concepto y el monto son requeridos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setGastoData({
                id: null,
                concepto: '', 
                id_proveedor: null,  
                id_metodo: null,  
                monto: 0
            });

            setProveedorData(null);
            setMetodoData(null);

            setEditarModal(false);
            reestablecerGasto(null);
        }
    }

    useEffect(() => {
        if(gasto){
            setEditarModal(true);

            dispatch(obtenerGasto(gasto?.id));
        }        
    }, [gasto]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_proveedores.length; i++) {
            options.push({ value: listado_proveedores[i].id, label: listado_proveedores[i].alias ? listado_proveedores[i].alias + ' (' + listado_proveedores[i].razon_social + ')' : listado_proveedores[i].razon_social });
        }

        setProveedoresFilter(options);
    }, [listado_proveedores]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_metodos.length; i++) {
            options.push({ value: listado_metodos[i].id, label: listado_metodos[i].nombre });
        }

        setMetodosFilter(options);
    }, [listado_metodos]);

    useEffect(() => {
        if(detalles){
            if(!gastoData.id){
                setGastoData({
                    ...gastoData,
                    id: detalles?.id, 
                    concepto: detalles?.concepto, 
                    id_proveedor: detalles?.id_proveedor,  
                    id_metodo: detalles?.id_metodo,  
                    monto: detalles?.monto
                });
            }

            if(detalles?.id_proveedor){
                if(proveedoresFilter.length > 0){
                    let proveedor_asignado = proveedoresFilter.find( proveedor => String(proveedor.value) === String(detalles?.id_proveedor) );
        
                    if(proveedor_asignado){
                        setProveedorData({value: proveedor_asignado.value, label: proveedor_asignado.label});
                    }
                }
            }

            if(detalles?.id_metodo){
                if(metodosFilter.length > 0){
                    let metodo_asignado = metodosFilter.find( metodo => String(metodo.value) === String(detalles?.id_metodo) );
        
                    if(metodo_asignado){
                        setMetodoData({value: metodo_asignado.value, label: metodo_asignado.label});
                    }
                }
            }
        }
    }, [detalles, proveedoresFilter, metodosFilter]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <Modal show={editarModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar gasto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Concepto" id="concepto" name="concepto" value={gastoData.concepto} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="concepto">Concepto</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_proveedor"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Proveedor"
                                    options={proveedoresFilter}
                                    value={proveedorData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProveedor(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_metodo"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Método de pago"
                                    options={metodosFilter}
                                    value={metodoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectMetodo(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="monto"
                                        name="monto"
                                        className="form-control text-center"
                                        value={gastoData.monto || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value) => handleNumberChange(value)}
                                    />
                                    <label htmlFor="monto">Monto real</label>
                                </div>
                            </div>
                        </div>
                    </div>           
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Guardar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Guardar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EditarGasto;