import axios from 'axios';

import { INVENTARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarInventario = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/inventario/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: INVENTARIO.BUSCAR,
            payload: res.data.productos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtarProductos = (opcion) => dispatch => {
    dispatch({
        type: INVENTARIO.FILTROS,
        payload: opcion
    });
}

export const limpiarProducto = () => dispatch => {
    dispatch({
        type: INVENTARIO.DETALLES,
        payload: null
    });
}

export const obtenerProducto = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/inventario/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: INVENTARIO.DETALLES,
            payload: res.data.inventarios
        });
    } catch (error){         
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}