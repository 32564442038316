import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarProveedores } from '../../../actions/proveedores';

import { formatNumber } from '../../../utils/generalFunctions';

import EliminarProveedor from './EliminarProveedor';

const Proveedores = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Proveedores',
            path: '/egresos/proveedores'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.proveedores);

    const [tipos, setTipos] = useState([]);

    const [proveedorEliminar, setProveedorEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Alias',
            wrap: true,
            selector: row => row.alias,
        },
        {
            name: 'Razón social',
            wrap: true,
            selector: row => row.razon_social,
        },
        {
            name: 'RFC',
            selector: row => row.rfc,
        },
        {
            name: 'Contacto',
            selector: row => row.nombre_contacto,
        },
        {
            name: 'Teléfono',
            selector: row => row.telefono,
        },
        {
            name: 'Correo',
            wrap: true,
            selector: row => row.correo,
        },
        {
            name: 'Ordenes generadas',
            selector: row => formatNumber(row.ordenes, 0, '', '')
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarProveedor(row)}><i className="fas fa-trash text-danger"></i></button>
                        <Link className="btn btn-sm btn-link px-2 float-end" to={`/egresos/proveedores/editar/${row.id}`}><i className="fas fa-edit text-warning"></i></Link>
                    </div>
                )
            }
        }
    ];

    /* Eliminar tipo */
    const handleEliminarProveedor = (tipo) => {
        setProveedorEliminar(tipo);
    }

    useEffect(() => {
        dispatch(buscarProveedores());
    }, []);

    useEffect(() => {
        setTipos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <EliminarProveedor proveedor={proveedorEliminar} reestablecerProveedor={handleEliminarProveedor} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Proveedores</h3>

                    <div className="d-flex justify-content-end">
                        <Link to="/egresos/proveedores/nuevo">
                            <button className="btn btn-primary">Agregar proveedor</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={tipos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Proveedores;