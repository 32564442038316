import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../../actions/alert';
import { buscarAreasConfiguracion, buscarOrganizadoresConfiguracion, buscarUbicacionesConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const UbicacionesProducto = ({ producto, posicion, reestablecerProducto, actualizarProducto }) => {

    const dispatch =  useDispatch();

    const { listado: listado_areas } = useSelector(state => state.configuracion.areas);
    const { listado: listado_organizadores } = useSelector(state => state.configuracion.organizadores);
    const { listado: listado_ubicaciones } = useSelector(state => state.configuracion.ubicaciones);

    const [ubicacionesModal, setUbicacionesModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [productoData, setProductoData] = useState({
        id: null,
        nombre: null,
        precio_compra: null,
        cantidad_surtir: null,
        cantidad_sin_asignar: null,
        cantidad_asignada: null,
        unidad: null,
        unidades: [], 
        ubicaciones: [],
        areas_selects: [],
        organizadores_selects: [],
        ubicaciones_selects: []
    });

    const [areasData, setAreasData] = useState([]);
    const [areasFilter, setAreasFilter] = useState([]);

    const [organizadoresData, setOrganizadoresData] = useState([]);
    const [organizadoresFilter, setOrganizadoresFilter] = useState([]);

    const [ubicacionesData, setUbicacionesData] = useState([]);
    const [ubicacionesFilter, setUbicacionesFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Ubicaciones de producto */
    const handleAbrir = () => {
        setProductoData({
            id: producto.id || null,
            nombre: producto.nombre || 'Sin definir',
            precio_compra: producto.precio_compra || 0,
            cantidad_surtir: producto.cantidad_surtir || 0,
            cantidad_sin_asignar: producto.cantidad_sin_asignar || 0,
            cantidad_asignada: producto.cantidad_asignada || 0,
            unidad: producto.unidad || null,
            unidades: producto.unidades || [],
            ubicaciones: producto.ubicaciones || [],
            areas_selects: producto.areas_selects || [],
            organizadores_selects: producto.organizadores_selects || [],
            ubicaciones_selects: producto.ubicaciones_selects || []
        });

        setAreasData(producto.areas_selects || []);
        setOrganizadoresData(producto.organizadores_selects || []);
        setUbicacionesData(producto.ubicaciones_selects || []);

        dispatch(buscarAreasConfiguracion());
    }

    const handleAgregarUbicacion = async () => {
        if((productoData.cantidad_sin_asignar - productoData.cantidad_asignada) > 0){
            let ubicaciones = productoData.ubicaciones;
            let areas_elegidas = areasData;
            let organizadores_elegidos = organizadoresData;
            let ubicaciones_elegidas = ubicacionesData;

            ubicaciones.push({
                cantidad: 0,
                area: null,
                organizador: null,
                ubicacion: null
            });

            areas_elegidas.push(null);
            organizadores_elegidos.push(null);
            ubicaciones_elegidas.push(null);

            setProductoData({
                ...productoData, 
                ubicaciones
            });
        }else{
            await dispatch(setAlert('La cantidad por asignar es 0', 'danger'));
        }
    }

    const handleFilasUbicaciones = (ubicaciones) => {

        let ubicaciones_filas = [];

        if(ubicaciones?.length){
            for (let x = 0; x < ubicaciones?.length; x++) {
                ubicaciones_filas.push(
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="form-floating h-100">
                                    <Select
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder={'Área ' + (x + 1) }
                                        options={areasFilter}
                                        value={areasData[x]}
                                        isClearable={true}
                                        onChange={(value) => handleSelectArea(value, x)}
                                        menuPortalTarget={document.body}
                                        menuPlacement="auto"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{ ...styles_select }}
                                    />
                                </div>
                                <div className="form-floating h-100">
                                    <Select
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder={'Organizador ' + (x + 1) }
                                        options={organizadoresFilter}
                                        value={organizadoresData[x]}
                                        isClearable={true}
                                        onChange={(value) => handleSelectOrganizador(value, x)}
                                        menuPortalTarget={document.body}
                                        menuPlacement="auto"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{ ...styles_select }}
                                    />
                                </div>
                                <div className="form-floating h-100">
                                    <Select
                                        className="select-group"
                                        classNamePrefix="react-select"
                                        placeholder={'Ubicación ' + (x + 1) }
                                        options={ubicacionesFilter}
                                        value={ubicacionesData[x]}
                                        isClearable={true}
                                        onChange={(value) => handleSelectUbicacion(value, x)}
                                        menuPortalTarget={document.body}
                                        menuPlacement="auto"
                                        components={{
                                            ValueContainer: CustomValueContainer
                                        }}
                                        styles={{ ...styles_select }}
                                    />
                                </div>
                                <div className="form-floating">
                                    <input type="number" className="form-control" placeholder={'Cantidad ' + (x + 1)} id={'cantidad_' + x} name={'cantidad_' + x} value={ubicaciones[x].cantidad || ''} onChange={e => handleCantidadChange(e)}/>
                                    <label htmlFor={'cantidad_' + x}>Cantidad {x + 1}</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-danger delete" id={'eliminarAnotacion_' + x} onClick={e => handleEliminarUbicacion(e)}><i className="fa-solid fa-xmark"></i></button>
                                </div>
                            </div>                            
                        </div>
                    </div>
                );
            }
        }else{
            ubicaciones_filas.push(<div className="col-md-12 form-group mt-3 text-center"><p>No hay ubicaciones registradas.</p></div>);
        }

        return ubicaciones_filas;
    }

    const handleCantidadChange = ({ target }) => {
        let cantidad_asignada = 0;
        let nombre_separado = target.name.split('_');

        let posicion = nombre_separado[1];
        
        let ubicaciones = productoData.ubicaciones;

        ubicaciones[posicion].cantidad = target.value ? (target.value >= 0 ? target.value : '') : '';

        for (var i = 0; i < ubicaciones.length; i++) {
            cantidad_asignada += ubicaciones[i].cantidad ? parseFloat(ubicaciones[i].cantidad) : 0;
        }

        setProductoData({
            ...productoData,
            ubicaciones, 
            cantidad_asignada
        });
    }

    const handleEliminarUbicacion = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');
        let cantidad_asignada = 0;

        let ubicaciones = productoData.ubicaciones;
        let areas_elegidas = areasData;
        let organizadores_elegidos = organizadoresData;
        let ubicaciones_elegidas = ubicacionesData;

        ubicaciones.splice(id_separado[1], 1);
        areas_elegidas.splice(id_separado[1], 1);
        organizadores_elegidos.splice(id_separado[1], 1);
        ubicaciones_elegidas.splice(id_separado[1], 1);

        for (var i = 0; i < ubicaciones.length; i++) {
            cantidad_asignada += ubicaciones[i].cantidad ? parseFloat(ubicaciones[i].cantidad) : 0;
        }

        setProductoData({ 
            ...productoData, 
            ubicaciones, 
            cantidad_asignada
        });

        setAreasData(areas_elegidas);
        setOrganizadoresData(organizadores_elegidos);
        setUbicacionesData(ubicaciones_elegidas);
    }

    const handleSelectArea = (option, posicion) => {
        let ubicaciones = productoData.ubicaciones;
        let areas_elegidas = areasData;
        let organizadores_elegidos = organizadoresData;
        let ubicaciones_elegidas = ubicacionesData;

        ubicaciones[posicion] = {
            ...ubicaciones[posicion], 
            area: option?.value || null,
            organizador: null,
            ubicacion: null
        };

        areas_elegidas[posicion] = option;
        organizadores_elegidos[posicion] = null;
        ubicaciones_elegidas[posicion] = null;

        setProductoData({
            ...productoData,
            ubicaciones, 
            areas_selects: areas_elegidas,
            organizadores_selects: organizadores_elegidos,
            ubicaciones_selects: ubicaciones_elegidas
        });

        setAreasData(areas_elegidas);
        setOrganizadoresData(organizadores_elegidos);
        setUbicacionesData(ubicaciones_elegidas);

        if(option?.value){
            dispatch(buscarOrganizadoresConfiguracion(option?.value));
        }else{
            setOrganizadoresFilter([]);
            setUbicacionesFilter([]);
        }
    }

    const handleSelectOrganizador = (option, posicion) => {
        let ubicaciones = productoData.ubicaciones;
        let organizadores_elegidos = organizadoresData;
        let ubicaciones_elegidas = ubicacionesData;

        ubicaciones[posicion] = {
            ...ubicaciones[posicion], 
            organizador: option?.value || null,
            ubicacion: null
        };

        organizadores_elegidos[posicion] = option;
        ubicaciones_elegidas[posicion] = null;

        setProductoData({
            ...productoData,
            ubicaciones, 
            organizadores_selects: organizadores_elegidos,
            ubicaciones_selects: ubicaciones_elegidas
        });

        setOrganizadoresData(organizadores_elegidos);
        setUbicacionesData(ubicaciones_elegidas);

        if(option?.value){
            dispatch(buscarUbicacionesConfiguracion(option?.value));
        }else{
            setUbicacionesFilter([]);
        }
    }

    const handleSelectUbicacion = (option, posicion) => {
        let ubicaciones = productoData.ubicaciones;
        let ubicaciones_elegidas = ubicacionesData;

        ubicaciones[posicion] = {
            ...ubicaciones[posicion], 
            ubicacion: option?.value || null
        };

        ubicaciones_elegidas[posicion] = option;

        setProductoData({
            ...productoData,
            ubicaciones, 
            ubicaciones_selects: ubicaciones_elegidas
        });

        setUbicacionesData(ubicaciones_elegidas);
    }
    
    const handleProcesar = async () => {
        if((productoData.cantidad_sin_asignar - productoData.cantidad_asignada) >= 0){
            let ubicaciones_completas = true;

            for (var i = 0; i < productoData.ubicaciones.length; i++) {
                ubicaciones_completas = productoData.ubicaciones[i].area || productoData.ubicaciones[i].organizador || productoData.ubicaciones[i].ubicacion;
            }

            if(ubicaciones_completas){
                await setStatusProcesar(true);
                await actualizarProducto(productoData, posicion);
                await handleCerrar(null);
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('Debe de estar al menos elegida el área en cada ubicación', 'danger'));
            }
        }else{
            await dispatch(setAlert('La cantidad sin asignar no puede ser menor a 0', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setAreasData([]);
            setOrganizadoresData([]);
            setUbicacionesData([]);
            reestablecerProducto(null, null);
            setUbicacionesModal(false);
        }
    }

    useEffect(() => {
        if(producto != null){
            setUbicacionesModal(true);
        }
    }, [producto]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_areas.length; i++) {
            options.push({ value: listado_areas[i].id, label: listado_areas[i].nombre });
        }

        setAreasFilter(options);
    }, [listado_areas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_organizadores.length; i++) {
            options.push({ value: listado_organizadores[i].id, label: listado_organizadores[i].nombre });
        }

        setOrganizadoresFilter(options);
    }, [listado_organizadores]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_ubicaciones.length; i++) {
            options.push({ value: listado_ubicaciones[i].id, label: listado_ubicaciones[i].nombre });
        }

        setUbicacionesFilter(options);
    }, [listado_ubicaciones]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .valor_indicador {
                            font-size: 20px;
                            font-weight: 600;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            font-size: 15-px;
                            margin: 0;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={ubicacionesModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Asignación de ubicaciones</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ productoData?.nombre }</p>
                            <p className="descripcion_indicador">Producto</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador"><h4><Badge bg={(productoData?.cantidad_sin_asignar - productoData?.cantidad_asignada) == 0 ? 'success' : ((productoData?.cantidad_sin_asignar - productoData?.cantidad_asignada) > 0 ? 'warning' : 'danger')}>{productoData?.cantidad_sin_asignar - productoData?.cantidad_asignada}</Badge></h4></p>
                            <p className="descripcion_indicador">Cantidad sin asignar</p>
                        </div>
                    </div>
                    { handleFilasUbicaciones(productoData.ubicaciones) }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => handleAgregarUbicacion()}><i className="fas fa-plus fa-sm"></i> Agregar ubicación</button>
                    {
                        statusProcesar ? (
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Aceptar</button>
                        ) : (
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Aceptar</button>
                        )
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default UbicacionesProducto;