import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { cancelarVenta } from '../../../actions/ventas';

import { formatNumber } from '../../../utils/generalFunctions';

const CancelarVenta = ({ venta, reestablecerVenta }) => {

    const dispatch =  useDispatch();

    const [cancelarModal, setCancelarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Cancelar venta */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(cancelarVenta(venta));
        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerVenta(null);
            setCancelarModal(false);
        }
    }

    useEffect(() => {
        if(venta){
            setCancelarModal(true);
        }
    }, [venta]);

    return (
        <Modal show={cancelarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Cancelar venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas cancelar la venta hecha a "{venta?.cliente?.nombre ? venta?.cliente?.nombre + ' ' + venta?.cliente?.apellido : 'Público general'}" por un total de {formatNumber(venta?.total || 0, 2, '$ ', '')}? Totos los articulos regresarán al inventario y los servicios no serán tomados en cuenta.</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={ statusProcesar }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default CancelarVenta;