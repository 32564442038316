import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { buscarAreasConfiguracion, buscarOrganizadoresConfiguracion, buscarUbicacionesConfiguracion } from '../../../actions/configuracion';
import { crearMovimiento, buscarProductos } from '../../../actions/movimientos';

import { formatNumber } from '../../../utils/generalFunctions';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevoMovimiento = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Movimientos',
            path: '/movimientos'
        },
        {
            activo: true,
            nombre: 'Nuevo movimiento',
            path: '/movimientos/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { listado: listado_areas } = useSelector(state => state.configuracion.areas);
    const { listado: listado_organizadores } = useSelector(state => state.configuracion.organizadores);
    const { listado: listado_ubicaciones } = useSelector(state => state.configuracion.ubicaciones);
    const { listado_productos } = useSelector(state => state.movimientos);

    const [movimientoData, setMovimientoData] = useState({
        id_producto: null,
        cantidad_origen: 0, 
        conversion: 1, 
        cantidad: 0, 
        id_area_origen: null,
        id_organizador_origen: null,
        id_ubicacion_origen: null, 
        id_area_destino: null,
        id_organizador_destino: null,
        id_ubicacion_destino: null
    });

    const [areaOrigenData, setAreaOrigenData] = useState(null);
    const [areaDestinoData, setAreaDestinoData] = useState(null);
    const [areasFilter, setAreasFilter] = useState([]);

    const [organizadorOrigenData, setOrganizadorOrigenData] = useState(null);
    const [organizadorDestinoData, setOrganizadorDestinoData] = useState(null);
    const [organizadoresFilter, setOrganizadoresFilter] = useState([]);

    const [ubicacionOrigenData, setUbicacionOrigenData] = useState(null);
    const [ubicacionDestinoData, setUbicacionDestinoData] = useState(null);
    const [ubicacionesFilter, setUbicacionesFilter] = useState([]);

    const [productoData, setProductoData] = useState(null);
    const [productosFilter, setProductosFilter] = useState([]);

    const [unidadData, setUnidadData] = useState(null);
    const [unidadesFilter, setUnidadesFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Nuevo movimiento */

    // Origen
    const handleSelectAreaOrigen = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_producto: null,
            cantidad_origen: 0, 
            conversion: 1, 
            cantidad: 0, 
            id_area_origen: option ? option.value : null,
            id_organizador_origen: null,
            id_ubicacion_origen: null
        });

        setProductoData(null);
        setUnidadData(null);
        setAreaOrigenData(option);
        setOrganizadorOrigenData(null);
        setUbicacionOrigenData(null);

        dispatch(buscarProductos({
            area: option ? option.value : null, 
            organizador: null, 
            ubicacion: null
        }));

        if(option?.value){
            dispatch(buscarOrganizadoresConfiguracion(option?.value));
        }else{
            setOrganizadoresFilter([]);
            setUbicacionesFilter([]);
            setProductosFilter([]);
            setUnidadesFilter([]);
        }
    }

    const handleSelectOrganizadorOrigen = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_producto: null,
            cantidad_origen: 0, 
            conversion: 1, 
            cantidad: 0, 
            id_organizador_origen: option ? option.value : null, 
            id_ubicacion_origen: null
        });

        setProductoData(null);
        setUnidadData(null);
        setOrganizadorOrigenData(option);
        setUbicacionOrigenData(null);

        dispatch(buscarProductos({
            area: movimientoData.id_area_origen, 
            organizador: option ? option.value : null, 
            ubicacion: null
        }));

        if(option?.value){
            dispatch(buscarUbicacionesConfiguracion(option?.value));
        }else{
            setUbicacionesFilter([]);
            setProductosFilter([]);
            setUnidadesFilter([]);
        }
    }

    const handleSelectUbicacionOrigen = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_producto: null,
            cantidad_origen: 0, 
            conversion: 1, 
            cantidad: 0, 
            id_ubicacion_origen: option ? option.value : null
        });

        setProductoData(null);
        setUnidadData(null);
        setUbicacionOrigenData(option);

        dispatch(buscarProductos({
            area: movimientoData.id_area_origen, 
            organizador: movimientoData.id_organizador_origen, 
            ubicacion: option ? option.value : null
        }));

        if(!option?.value){
            setProductosFilter([]);
            setUnidadesFilter([]);
        }
    }

    const handleSelectProducto = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_producto: option ? option.value : null, 
            cantidad_origen: option ? option.cantidad : 0, 
            conversion: 1, 
            cantidad: 0
        });

        setProductoData(option);
        setUnidadData(null);

        if(option?.unidades){
            setUnidadesFilter(option?.unidades);
        }else{
            setUnidadesFilter([]);
        }
    }

    const handleSelectUnidad = (option) => {
        setMovimientoData({
            ...movimientoData,
            conversion: option ? option.cantidad : 1
        });

        setUnidadData(option);
    }

    // Destino
    const handleSelectAreaDestino = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_area_destino: option ? option.value : null,
            id_organizador_destino: null,
            id_ubicacion_destino: null
        });

        setAreaDestinoData(option);
        setOrganizadorDestinoData(null);
        setUbicacionDestinoData(null);

        if(option?.value){
            dispatch(buscarOrganizadoresConfiguracion(option?.value));
        }else{
            setOrganizadoresFilter([]);
            setUbicacionesFilter([]);
        }
    }

    const handleSelectOrganizadorDestino = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_organizador_destino: option ? option.value : null, 
            id_ubicacion_destino: null
        });

        setOrganizadorDestinoData(option);
        setUbicacionDestinoData(null);

        if(option?.value){
            dispatch(buscarUbicacionesConfiguracion(option?.value));
        }else{
            setUbicacionesFilter([]);
        }
    }

    const handleSelectUbicacionDestino = (option) => {
        setMovimientoData({
            ...movimientoData,
            id_ubicacion_destino: option ? option.value : null
        });

        setUbicacionDestinoData(option);
    }

    const handleNumberChange = (value) => {
        setMovimientoData({
            ...movimientoData,
            cantidad: value || ''
        });
    }

    const handleGuardarOrden = async () => {
        if(movimientoData.cantidad_origen){
            if(movimientoData.cantidad){
                if(movimientoData.cantidad <= (parseFloat(movimientoData.cantidad_origen) * parseFloat(movimientoData.conversion || 1))){
                    await dispatch(crearMovimiento(movimientoData));
        
                    await navigate("/movimientos");
                }else{
                    await dispatch(setAlert('No puedes mover más cantidad de la existente', 'danger'));
                }
            }else{
                await dispatch(setAlert('La cantidad a mover debe ser mayor a 0', 'danger'));
            }
        }else{
            await dispatch(setAlert('Debes elegir un producto con existencias', 'danger'));
        }
    }

    useEffect(() => {
        setAreasFilter([]);
        setOrganizadoresFilter([]);
        setUbicacionesFilter([]);
        setProductosFilter([]);
        setUnidadesFilter([]);

        dispatch(buscarAreasConfiguracion());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_areas.length; i++) {
            options.push({ value: listado_areas[i].id, label: listado_areas[i].nombre });
        }

        setAreasFilter(options);
    }, [listado_areas]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_organizadores.length; i++) {
            options.push({ value: listado_organizadores[i].id, label: listado_organizadores[i].nombre });
        }

        setOrganizadoresFilter(options);
    }, [listado_organizadores]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_ubicaciones.length; i++) {
            options.push({ value: listado_ubicaciones[i].id, label: listado_ubicaciones[i].nombre });
        }

        setUbicacionesFilter(options);
    }, [listado_ubicaciones]);

    useEffect(() => {
        let options = [];

        if(areaOrigenData){
            for (var i = 0; i < listado_productos.length; i++) {
                options.push({ value: listado_productos[i].producto?.id, label: listado_productos[i].producto?.nombre, cantidad: listado_productos[i].cantidad, unidades: listado_productos[i].producto?.unidades });
            }
        }

        setProductosFilter(options);
    }, [listado_productos]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-5 mt-5">Nuevo movimiento</h3>


                    <div className="row mb-3 mt-5">
                        <h5 className="mb-4">Origen</h5>
                    
                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_area_origen"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Área"
                                    options={areasFilter}
                                    value={areaOrigenData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectAreaOrigen(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_organizador_origen"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Organizador"
                                    options={organizadoresFilter}
                                    value={organizadorOrigenData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectOrganizadorOrigen(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_ubicacion_origen"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Ubicación"
                                    options={ubicacionesFilter}
                                    value={ubicacionOrigenData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectUbicacionOrigen(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_producto"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Producto"
                                    options={productosFilter}
                                    value={productoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProducto(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_unidad"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Unidad"
                                    options={unidadesFilter}
                                    value={unidadData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectUnidad(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input
                                        id="cantidad_origen"
                                        name="cantidad_origen"
                                        type="text" 
                                        className="form-control text-center"
                                        value={formatNumber((parseFloat(movimientoData.cantidad_origen) * parseFloat(movimientoData.conversion || 1)), 2, '', '')}
                                        disabled
                                    />
                                    <label htmlFor="cantidad_origen">Cantidad en inventario</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="mt-4 mb-4" />

                    <div className="row mb-3">
                        <h5 className="mb-4">Destino</h5>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_area_destino"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Área"
                                    options={areasFilter}
                                    value={areaDestinoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectAreaDestino(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_organizador_destino"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Organizador"
                                    options={organizadoresFilter}
                                    value={organizadorDestinoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectOrganizadorDestino(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_ubicacion_destino"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Ubicación"
                                    options={ubicacionesFilter}
                                    value={ubicacionDestinoData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectUbicacionDestino(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="cantidad"
                                        name="cantidad"
                                        className="form-control text-center"
                                        value={movimientoData.cantidad || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value) => handleNumberChange(value)}
                                    />
                                    <label htmlFor="cantidad">Cantidad a mover</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarOrden}>Guardar</button>
                            <Link to='/movimientos'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoMovimiento;