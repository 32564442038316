import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../../actions/alert';
import { crearServicioConfiguracion, buscarCategoriasConfiguracion, buscarSubcategoriasConfiguracion, buscarSubsubcategoriasConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevoServicio = ({ servicio, reestablecerServicio }) => {

    const dispatch =  useDispatch();

    const { listado: listado_categorias } = useSelector(state => state.configuracion.categorias);
    const { listado: listado_subcategorias } = useSelector(state => state.configuracion.subcategorias);
    const { listado: listado_subsubcategorias } = useSelector(state => state.configuracion.subsubcategorias);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [servicioData, setServicioData] = useState({
        id_categoria: null,  
        id_subcategoria: null,  
        id_subsubcategoria: null,  
        nombre: '', 
        precio_venta: '', 
        descripcion: ''
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [subcategoriaData, setSubcategoriaData] = useState(null);
    const [subcategoriasFilter, setSubcategoriasFilter] = useState([]);

    const [subsubcategoriaData, setSubsubcategoriaData] = useState(null);
    const [subsubcategoriasFilter, setSubsubcategoriasFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Crear servicio */
    const handleAbrir = () => {
        dispatch(buscarCategoriasConfiguracion());
    }

    const handleInputChange = ({ target }) => {
        setServicioData({
            ...servicioData,
            [target.name]: target.value
        });
    }

    const handleSelectCategoria = (option) => {
        setServicioData({
            ...servicioData,
            id_categoria: option ? option.value : null, 
            id_subcategoria: null
        });

        setCategoriaData(option);
        setSubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubcategoriasConfiguracion(option?.value));
        }else{
            setSubcategoriasFilter([]);
        }
    }

    const handleSelectSubcategoria = (option) => {
        setServicioData({
            ...servicioData,
            id_subcategoria: option ? option.value : null, 
            id_subsubcategoria: null
        });

        setSubcategoriaData(option);
        setSubsubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubsubcategoriasConfiguracion(option?.value));
        }else{
            setSubsubcategoriasFilter([]);
        }
    }

    const handleSelectSubsubcategoria = (option) => {
        setServicioData({
            ...servicioData,
            id_subsubcategoria: option ? option.value : null
        });

        setSubsubcategoriaData(option);
    }

    const handleProcesar = async () => {
        if(servicioData.nombre && servicioData.precio_venta){
            await setStatusProcesar(true);
            await dispatch(crearServicioConfiguracion(servicioData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('El nombre y precio de venta son requeridos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setServicioData({
                id_categoria: null,  
                id_subcategoria: null,  
                id_subsubcategoria: null,  
                nombre: '', 
                precio_venta: '', 
                descripcion: ''
            });

            setCategoriaData(null);
            setSubcategoriaData(null);
            setSubsubcategoriaData(null);

            setNuevoModal(false);
            reestablecerServicio(null);
        }
    }

    useEffect(() => {
        if(servicio){
            setNuevoModal(true);

            setServicioData({
                ...servicioData
            });
        }        
    }, [servicio]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_categorias.length; i++) {
            options.push({ value: listado_categorias[i].id, label: listado_categorias[i].nombre });
        }

        setCategoriasFilter(options);
    }, [listado_categorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subcategorias.length; i++) {
            options.push({ value: listado_subcategorias[i].id, label: listado_subcategorias[i].nombre });
        }

        setSubcategoriasFilter(options);
    }, [listado_subcategorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subsubcategorias.length; i++) {
            options.push({ value: listado_subsubcategorias[i].id, label: listado_subsubcategorias[i].nombre });
        }

        setSubsubcategoriasFilter(options);
    }, [listado_subsubcategorias]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .select-group {
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={nuevoModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-9 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={servicioData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Precio de venta" id="precio_venta" name="precio_venta" value={servicioData.precio_venta} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="precio_venta">Precio de venta</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_categoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Categoría"
                                    options={categoriasFilter}
                                    value={categoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectCategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_subcategoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Subcategoría"
                                    options={subcategoriasFilter}
                                    value={subcategoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectSubcategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    name="id_subsubcategoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Subsubcategoría"
                                    options={subsubcategoriasFilter}
                                    value={subsubcategoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectSubsubcategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={servicioData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                        :
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevoServicio;