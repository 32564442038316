import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarClientes, filtrarClientes } from '../../../actions/clientes';

import { formatNumber } from '../../../utils/generalFunctions';

import FiltrosCliente from './FiltrosCliente';
import NuevoCliente from './NuevoCliente';
import EditarCliente from './EditarCliente';
import EliminarCliente from './EliminarCliente';

const Clientes = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Clientes',
            path: '/ingresos/clientes'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.clientes);

    const [clientes, setClientes] = useState([]);

    const [gastoAgregar, setGastoAgregar] = useState(null);
    const [gastoEditar, setGastoEditar] = useState(null);
    const [gastoEliminar, setGastoEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre, 
            grow: 1.5,
        },
        {
            name: 'Apellido',
            selector: row => row.apellido, 
            grow: 1.5
        },
        {
            name: 'Correo',
            selector: row => row.correo,
            grow: 2
        },
        {
            name: 'Teléfono(s)',
            selector: row => row.telefono ? row.telefono + (row.celular ? ', ' + row.telefono : '') : row.celular, 
            grow: 2
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarGasto(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarGasto(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarClientes(true));
    }

    /* Crear gasto */
    const handleAgregarGasto = (gasto) => {
        setGastoEditar(null);
        setGastoEliminar(null);

        setGastoAgregar(gasto);        
    }

    /* Editar gasto */
    const handleEditarGasto = (gasto) => {
        setGastoAgregar(null);
        setGastoEliminar(null);

        setGastoEditar(gasto);
    }

    /* Eliminar gasto */
    const handleEliminarGasto = (gasto) => {
        setGastoAgregar(null);
        setGastoEditar(null);

        setGastoEliminar(gasto);
    }

    useEffect(() => {
        dispatch(buscarClientes({
            nombre: null
        }));
    }, []);

    useEffect(() => {
        setClientes(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosCliente />
            <NuevoCliente gasto={gastoAgregar} reestablecerGasto={handleAgregarGasto} />
            <EditarCliente gasto={gastoEditar} reestablecerGasto={handleEditarGasto} />
            <EliminarCliente gasto={gastoEliminar} reestablecerGasto={handleEliminarGasto} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Clientes</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarGasto({ })}>Agregar cliente</button>
                    </div>

                    <div className="mt-3">
                        <DataTable 
                            columns={columns}
                            data={clientes}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Clientes;