import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import DataTable from 'react-data-table-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { setAlert } from '../../../actions/alert';
import { buscarClientes } from '../../../actions/clientes';
import { buscarMetodosConfiguracion } from '../../../actions/configuracion';
import { buscarProductos, buscarServicios, crearVenta } from '../../../actions/punto';

import { formatNumber } from '../../../utils/generalFunctions';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const Punto = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Nueva venta',
            path: '/ingresos/punto'
        }
    ];

    const dispatch = useDispatch();

    const { listado: listado_clientes } = useSelector(state => state.clientes);
    const { listado: listado_metodos } = useSelector(state => state.configuracion.metodos);
    const { listado_productos, listado_servicios } = useSelector(state => state.punto);

    const [ventaData, setVentaData] = useState({
        id_cliente: null,
        id_metodo: null,
        total: 0, 
        busqueda_cliente: null,
        busqueda_producto: null,
        busqueda_servicio: null,
        productos: [], 
        servicios: []
    });

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [unidadesData, setUnidadesData] = useState([]);

    const [buscadorClientes, setBuscadorClientes] = useState(false);
    const [buscadorProductos, setBuscadorProductos] = useState(false);
    const [buscadorServicios, setBuscadorServicios] = useState(false);

    const [clienteData, setClienteData] = useState(null);
    const [clientesFilter, setClientesFilter] = useState([]);
    
    const [metodoData, setMetodoData] = useState(null);
    const [metodosFilter, setMetodosFilter] = useState([]);

    const [tipoVenta, setTipoVenta] = useState(0);

    const [productoData, setProductoData] = useState(null);
    const [productosFilter, setProductosFilter] = useState([]);

    const [servicioData, setServicioData] = useState(null);
    const [serviciosFilter, setServiciosFilter] = useState([]);

    const columnsProductos = [
        {
            name: 'Producto',
            cell: row => row.nombre,
            grow: 4
        },
        {
            name: 'Precio',
            cell: row => formatNumber(row.precio / row.conversion, 2, '$ ', '')
        }, 
        {
            name: 'Inventario',
            cell: row => formatNumber(row.inventario * row.conversion, 0, '', '')
        },
        {
            name: 'Cantidad',
            cell: (row, index) => {
                return(
                    <input type="number" className="form-control" value={ventaData.productos[index]?.cantidad} onChange={e => handleCantidadChange(e, index)}/>
                )
            }
        }, 
        {
            name: 'Unidad',
            grow: 1.5,
            cell: (row, index) => {
                return(
                    <div className="input-group h-100 py-2">
                        <Select
                            className="select-group"
                            classNamePrefix="react-select"
                            placeholder="Inventario"
                            options={row.unidades}
                            value={unidadesData[index]}
                            isClearable={true}
                            onChange={(value) => handleSelectUnidad(value, index)}
                            menuPortalTarget={document.body}
                            menuPlacement="auto"
                            components={{
                                ValueContainer: CustomValueContainer
                            }}
                            styles={{ ...styles_select }}
                        />
                    </div>
                )
            }
        }, 
        {
            name: '',
            cell: (row, index) => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleRemoveProducto(index)}><i className="fas fa-trash text-danger"></i></button>
                    </div>
                )
            }
        }
    ];

    const columnsServicios = [
        {
            name: 'Servicio',
            cell: row => row.nombre,
            grow: 4
        },
        {
            name: 'Precio actual',
            cell: row => formatNumber(row.precio_actual, 2, '$ ', '')
        }, 
        {
            name: 'Precio final',
            cell: (row, index) => {
                return(
                    <CurrencyInput
                        className="form-control text-center"
                        value={ventaData.servicios[index]?.precio || ''}
                        decimalsLimit={2}
                        groupSeparator=","
                        decimalSeparator="."
                        onValueChange={(value) => handlePrecioChange(value, index)}
                    />
                )
            }
        }, 
        {
            name: '',
            cell: (row, index) => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleRemoveServicio(index)}><i className="fas fa-trash text-danger"></i></button>
                    </div>
                )
            }
        }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    const handleInputChange = ({ target }) => {
        setVentaData({
            ...ventaData,
            [target.name]: target.value
        });
    }

    //Clientes
    const handleBuscarClientes = () => {
        if(!buscadorClientes){
            dispatch(buscarClientes({
                nombre: ventaData.busqueda_cliente
            }));
        }else{
            setVentaData({
                ...ventaData,
                id_cliente: null, 
                busqueda_cliente: null
            });
    
            setClienteData(null);
        }

        setBuscadorClientes(!buscadorClientes);
    }

    const handleSelectCliente = (option) => {
        setVentaData({
            ...ventaData,
            id_cliente: option ? option.value : null
        });

        setClienteData(option);
    }

    //Metodo
    const handleSelectMetodo = (option) => {
        setVentaData({
            ...ventaData,
            id_metodo: option ? option.value : null
        });

        setMetodoData(option);
    }

    //Productos
    const handleBuscarProductos = () => {
        if(!buscadorProductos){
            dispatch(buscarProductos({
                nombre: ventaData.busqueda_producto, 
                codigo_barras: null
            }));
        }else{
            setVentaData({
                ...ventaData,
                busqueda_producto: null
            });
        }

        setBuscadorProductos(!buscadorProductos);
    }

    const handleBuscarCodigo = ({ target }) => {
        dispatch(buscarProductos({
            nombre: null, 
            codigo_barras: target.value
        }));

        setVentaData({
            ...ventaData,
            busqueda_producto: null
        });

        target.value = '';

        setBuscadorProductos(true);
    }

    const handleSelectProducto = (option) => {
        let productos = ventaData.productos;
        let unidades = unidadesData;

        if(option){
            let producto_existente = productos.some(producto => 
                producto.id_producto == option.value &&
                producto.id_area == option.area &&
                producto.id_organizador == option.organizador &&
                producto.id_ubicacion == option.ubicacion
            );

            if(!producto_existente){
                if(option.inventario){
                    productos.push({
                        id_producto: option.value, 
                        id_area: option.area, 
                        id_organizador: option.organizador, 
                        id_ubicacion: option.ubicacion, 
                        id_unidad: null, 
                        nombre: option.nombre_corto, 
                        inventario: option.inventario, 
                        precio: option.precio, 
                        conversion: 1, 
                        cantidad: 0, 
                        unidades: option.unidades
                    });

                    unidades.push(null);
                }else{
                    dispatch(setAlert('No puedes agregar productos sin existencias', 'danger'));
                }
            }else{
                dispatch(setAlert('Ya esta agregado este producto a la venta', 'danger'));
            }
        }

        setVentaData({
            ...ventaData,
            productos
        });

        setUnidadesData(unidades);

        setProductoData(null);
    }

    const handleCantidadChange = ({ target }, posicion) => {        
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let total = 0;

        let inventario_convertido = productos[posicion].inventario * productos[posicion].conversion;
        
        if(target.value <= inventario_convertido){
            if(target.value >= 0){
                productos[posicion].cantidad = target.value || '';

                for (var i = 0; i < productos.length; i++) {
                    total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
                }

                for (var i = 0; i < servicios.length; i++) {
                    total += parseFloat(servicios[i].precio || 0);
                }

                setVentaData({
                    ...ventaData,
                    productos, 
                    total
                });
            }else{
                dispatch(setAlert('No puedes vender cantidades negativas', 'danger'));
            }
        }else{
            dispatch(setAlert('La cantidad excede la de inventario', 'danger'));
        }
    }

    const handleSelectUnidad = (option, posicion) => {
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let unidades = unidadesData;
        let total = 0;

        let inventario_convertido = productos[posicion].inventario * (option ? option.cantidad : 1);

        if(productos[posicion].cantidad <= inventario_convertido){
            unidades[posicion] = option;

            productos[posicion].id_unidad = option ? option.value : null;
            productos[posicion].conversion = option ? option.cantidad : null;

            for (var i = 0; i < productos.length; i++) {
                total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
            }

            for (var i = 0; i < servicios.length; i++) {
                total += parseFloat(servicios[i].precio || 0);
            }

            setUnidadesData(unidades);

            setVentaData({
                ...ventaData,
                productos, 
                total
            });
        }else{
            dispatch(setAlert('La cantidad excede la de inventario', 'danger'));
        }
    }

    const handleRemoveProducto = (posicion) => {
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let unidades = unidadesData;
        let total = 0;

        productos.splice(posicion, 1);
        unidades.splice(posicion, 1);

        for (var i = 0; i < productos.length; i++) {
            total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
        }

        for (var i = 0; i < servicios.length; i++) {
            total += parseFloat(servicios[i].precio || 0);
        }

        setVentaData({
            ...ventaData,
            productos, 
            total
        });

        setUnidadesData(unidades);
    }

    //Servicios
    const handleBuscarServicios = () => {
        if(!buscadorServicios){
            dispatch(buscarServicios({
                nombre: ventaData.busqueda_servicio
            }));
        }else{
            setVentaData({
                ...ventaData,
                busqueda_servicio: null
            });
        }

        setBuscadorServicios(!buscadorServicios);
    }

    const handleSelectServicio = (option) => {
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let total = 0;

        if(option){
            let servicio_existente = servicios.some(servicio => 
                servicio.id_servicio == option.value
            );

            if(!servicio_existente){
                servicios.push({
                    id_servicio: option.value, 
                    nombre: option.label, 
                    precio_actual: option.precio,
                    precio: option.precio
                });

                for (var i = 0; i < productos.length; i++) {
                    total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
                }

                for (var i = 0; i < servicios.length; i++) {
                    total += parseFloat(servicios[i].precio || 0);
                }
            }else{
                dispatch(setAlert('Ya esta agregado este servicio a la venta', 'danger'));
            }
        }

        setVentaData({
            ...ventaData,
            servicios, 
            total
        });

        setServicioData(null);
    }

    const handlePrecioChange = (value, posicion) => {
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let total = 0;
        
        if(value >= 0){
            servicios[posicion].precio = value || '';

            for (var i = 0; i < productos.length; i++) {
                total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
            }

            for (var i = 0; i < servicios.length; i++) {
                total += parseFloat(servicios[i].precio || 0);
            }

            setVentaData({
                ...ventaData,
                servicios, 
                total
            });
        }else{
            dispatch(setAlert('No puedes vender montos negativos', 'danger'));
        }
    }

    const handleRemoveServicio = (posicion) => {
        let productos = ventaData.productos;
        let servicios = ventaData.servicios;
        let total = 0;

        servicios.splice(posicion, 1);

        for (var i = 0; i < productos.length; i++) {
            total += parseFloat(productos[i].cantidad || 0) * (parseFloat(productos[i].precio) / productos[i].conversion);
        }

        for (var i = 0; i < servicios.length; i++) {
            total += parseFloat(servicios[i].precio);
        }

        setVentaData({
            ...ventaData,
            servicios, 
            total
        });
    }

    //Vender
    const handleVender = async () => {
        if(ventaData.productos.length > 0 || ventaData.servicios.length > 0){
            if(ventaData.id_metodo){
                await setStatusProcesar(true);
                await dispatch(crearVenta(ventaData));
                await handleCancelar();
                await setStatusProcesar(false);
            }else{
                await dispatch(setAlert('Se debe seleccionar un método de cobro', 'danger'));
            }
        }else{
            await dispatch(setAlert('Debe de haber al menos un producto o servicio', 'danger'));
        }
    }

    const handleCancelar = async () => {
        setVentaData({
            id_cliente: null,
            id_metodo: null,
            total: 0, 
            busqueda_cliente: null,
            busqueda_producto: null,
            busqueda_servicio: null,
            productos: [],
            servicios: []
        });

        setUnidadesData([]);
        setClienteData(null);
        setMetodoData(null);

        setClientesFilter([]);
        setProductosFilter([]);
        setServiciosFilter([]);

        setBuscadorClientes(false);
        setBuscadorProductos(false);
        setBuscadorServicios(false);
    }

    useEffect(() => {
        dispatch(buscarMetodosConfiguracion());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_metodos.length; i++) {
            options.push({ value: listado_metodos[i].id, label: listado_metodos[i].nombre });
        }

        setMetodosFilter(options);
    }, [listado_metodos]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_clientes.length; i++) {
            options.push({ value: listado_clientes[i].id, label: [listado_clientes[i].nombre, listado_clientes[i].apellido].join(' ') });
        }

        setClientesFilter(options);
    }, [listado_clientes]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_productos.length; i++) {
            options.push({ value: listado_productos[i].id, label: listado_productos[i].nombre, nombre_corto: listado_productos[i].nombre_corto, area: listado_productos[i].area, organizador: listado_productos[i].organizador, ubicacion: listado_productos[i].ubicacion, inventario: listado_productos[i].inventario, precio: listado_productos[i].precio, unidades: listado_productos[i].unidades });
        }

        setProductosFilter(options);
    }, [listado_productos]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_servicios.length; i++) {
            options.push({ value: listado_servicios[i].id, label: listado_servicios[i].nombre, categoria: listado_servicios[i].categoria, subcategoria: listado_servicios[i].subcategoria, subsubcategoria: listado_servicios[i].subsubcategoria, precio: listado_servicios[i].precio_venta });
        }

        setServiciosFilter(options);
    }, [listado_servicios]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        html, body, #root {
                            height: 100%
                        }

                        #root{
                            display: flex !important;
                            flex-direction: column !important;
                        }

                        .container-fluid.principal-section{
                            flex-grow: 1;
                        }

                        .container-venta{
                            display: flex;    
                            flex-direction: column !important;
                            flex-grow: 1;
                        }

                        .select-group {
                            height: 100%;
                        }

                        .button-append {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <div className="container-venta px-4 pb-4 h-100">
                <h3 className="my-3">Nueva venta</h3>

                <div className="buscadores row">
                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group h-100">
                            {
                                buscadorClientes && 
                                    <div className="form-floating h-100">
                                        <Select
                                            name="id_cliente"
                                            className="select-group"
                                            classNamePrefix="react-select"
                                            placeholder="Cliente"
                                            options={clientesFilter}
                                            value={clienteData}
                                            isClearable={true}
                                            onChange={(value) => handleSelectCliente(value)}
                                            menuPortalTarget={document.body}
                                            menuPlacement="auto"
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                            styles={{ ...styles_select }}
                                        />
                                    </div>
                            }

                            {
                                !buscadorClientes && 
                                    <div className="form-floating">
                                        <input id="busqueda_cliente" name="busqueda_cliente" type="text" className="form-control text-center" onChange={e => handleInputChange(e)} />
                                        <label htmlFor="busqueda_cliente">Buscador de clientes</label>
                                    </div>
                            }

                            <div className="input-group-append">
                                <button className={'btn button-append btn-' + (buscadorClientes ? 'secondary' : 'primary')} onClick={() => handleBuscarClientes()}><i className={'fa-solid fa-' + (buscadorClientes ? 'xmark' : 'search')}></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="id_metodo"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Método de cobro"
                                options={metodosFilter}
                                value={metodoData}
                                isClearable={true}
                                onChange={(value) => handleSelectMetodo(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-3 flex-grow-1">
                    <ul className="nav nav-pills nav-fill">
                        <li className="nav-item">
                            <div className="input-group-append">
                                <button type="button" className={'nav-link border border-primary' + (tipoVenta == 0 ? ' text-black bg-primary' : '')} onClick={() => setTipoVenta(0)}>Productos ({ventaData.productos.length})</button>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="input-group-append">
                                <button type="button" className={'nav-link border border-primary' + (tipoVenta == 1 ? ' text-black bg-primary' : '')} onClick={() => setTipoVenta(1)}>Servicios ({ventaData.servicios.length})</button>
                            </div>
                        </li>
                    </ul>
                    
                    {
                        tipoVenta == 0 &&
                            <div className="productos row mt-3">
                                <div className="col-md-3 form-group mb-3">
                                    <div className="input-group">
                                        <div className="form-floating">
                                            <input id="codigo_barras" name="codigo_barras" type="text" className="form-control text-center" onKeyDown={(e) => {if (e.key == "Enter") { handleBuscarCodigo(e); }}} />
                                            <label htmlFor="codigo_barras">Código de barras</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-9 form-group mb-3">
                                    <div className="input-group">
                                        {
                                            buscadorProductos && 
                                                <div className="form-floating h-100">
                                                    <Select
                                                        name="id_producto"
                                                        className="select-group"
                                                        classNamePrefix="react-select"
                                                        placeholder="Productos"
                                                        options={productosFilter}
                                                        value={productoData}
                                                        isClearable={true}
                                                        onChange={(value) => handleSelectProducto(value)}
                                                        menuPortalTarget={document.body}
                                                        menuPlacement="auto"
                                                        components={{
                                                            ValueContainer: CustomValueContainer
                                                        }}
                                                        styles={{ ...styles_select }}
                                                    />
                                                </div>
                                        }

                                        {
                                            !buscadorProductos && 
                                                <div className="form-floating">
                                                    <input id="busqueda_producto" name="busqueda_producto" type="text" className="form-control text-center" onChange={e => handleInputChange(e)} />
                                                    <label htmlFor="busqueda_producto">Buscador de productos</label>
                                                </div>
                                        }

                                        <div className="input-group-append">
                                            <button className={'btn button-append btn-' + (buscadorProductos ? 'secondary' : 'primary')} onClick={() => handleBuscarProductos()}><i className={'fa-solid fa-' + (buscadorProductos ? 'xmark' : 'search')}></i></button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 form-group mb-3">
                                    <DataTable 
                                        columns={columnsProductos}
                                        data={ventaData.productos}
                                        highlightOnHover={true}
                                    />
                                </div>
                            </div>
                    }

                    {
                        tipoVenta == 1 &&
                            <div className="servicios row mt-3">
                                <div className="col-md-12 form-group mb-3">
                                    <div className="input-group">
                                        {
                                            buscadorServicios && 
                                                <div className="form-floating h-100">
                                                    <Select
                                                        name="id_servicio"
                                                        className="select-group"
                                                        classNamePrefix="react-select"
                                                        placeholder="Servicios"
                                                        options={serviciosFilter}
                                                        value={servicioData}
                                                        isClearable={true}
                                                        onChange={(value) => handleSelectServicio(value)}
                                                        menuPortalTarget={document.body}
                                                        menuPlacement="auto"
                                                        components={{
                                                            ValueContainer: CustomValueContainer
                                                        }}
                                                        styles={{ ...styles_select }}
                                                    />
                                                </div>
                                        }

                                        {
                                            !buscadorServicios && 
                                                <div className="form-floating">
                                                    <input id="busqueda_servicio" name="busqueda_servicio" type="text" className="form-control text-center" onChange={e => handleInputChange(e)} />
                                                    <label htmlFor="busqueda_servicio">Buscador de servicios</label>
                                                </div>
                                        }

                                        <div className="input-group-append">
                                            <button className={'btn button-append btn-' + (buscadorServicios ? 'secondary' : 'primary')} onClick={() => handleBuscarServicios()}><i className={'fa-solid fa-' + (buscadorServicios ? 'xmark' : 'search')}></i></button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 form-group mb-3">
                                    <DataTable 
                                        columns={columnsServicios}
                                        data={ventaData.servicios}
                                        highlightOnHover={true}
                                    />
                                </div>
                            </div>
                    }
                </div>

                <div className="botones row">
                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input id="cantidad_origen" name="cantidad_origen" type="text" className="form-control text-center" value={formatNumber(ventaData.total, 2, '$ ', '')} disabled />
                                <label htmlFor="cantidad_origen">Total a pagar</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-2 form-group mb-3">
                        <button type="button" className="btn btn-success w-100 h-100" onClick={handleVender} disabled={ statusProcesar }>Pagar</button>
                    </div>

                    <div className="col-md-2 form-group mb-3">
                        <button type="button" className="btn btn-danger w-100 h-100" onClick={handleCancelar} disabled={ statusProcesar }>Cancelar</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Punto;