import { CLIENTES } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CLIENTES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case CLIENTES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case CLIENTES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case CLIENTES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( cliente => cliente.id !== payload )
            };
        
        default:
            return state;
    }
}