import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarMovimientos } from '../../../actions/movimientos';

import { formatNumber } from '../../../utils/generalFunctions';

const Movimientos = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Movimientos',
            path: '/movimientos'
        }
    ];

    const dispatch = useDispatch();

    const { listado } = useSelector(state => state.movimientos);
    
    const [movimientos, setMovimientos] = useState([]);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Producto',
            cell: row => row.producto.nombre,
            grow: 2
        },
        {
            name: 'Cantidad',
            selector: row => formatNumber(row.cantidad, 0, '', '')
        },
        {
            name: 'Área Inicial',
            cell: row => row.area_origen?.nombre || 'Sin definir'
        },
        {
            name: 'Organizador Inicial',
            cell: row => row.organizador_origen?.nombre || 'Sin definir'
        },
        {
            name: 'Ubicación Inicial',
            cell: row => row.ubicacion_origen?.nombre || 'Sin definir'
        },
        {
            name: 'Área final',
            cell: row => row.area_destino?.nombre || 'Sin definir'
        },
        {
            name: 'Organizador final',
            cell: row => row.organizador_destino?.nombre || 'Sin definir'
        },
        {
            name: 'Ubicación final',
            cell: row => row.ubicacion_destino?.nombre || 'Sin definir'
        }, 
        {
            name: 'Fecha',
            grow: 1.5,
            cell: item => item.fecha && item.fecha != '0000-00-00' ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'
        },
    ];

    useEffect(() => {
        dispatch(buscarMovimientos());
    }, []);

    useEffect(() => {
        setMovimientos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Movimientos</h3>

                    <div className="d-flex justify-content-end">
                        <Link to="/movimientos/nuevo">
                            <button className="btn btn-primary">Generar movimiento</button>
                        </Link>
                    </div>

                    <div className="mt-3">
                        <DataTable 
                            columns={columns}
                            data={movimientos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Movimientos;