import { GASTOS } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case GASTOS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case GASTOS.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case GASTOS.AGREGAR:
            let gastos_agregar = state.listado;

            gastos_agregar = [
                ...gastos_agregar, payload
            ];

            gastos_agregar = gastos_agregar.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state, 
                listado: gastos_agregar
            };

        case GASTOS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case GASTOS.EDITAR:
            let gastos_editar = state.listado;

            gastos_editar = gastos_editar.map(gasto => gasto.id === payload.id ? payload : gasto);

            gastos_editar = gastos_editar.sort(function (a, b) {
                if (a.fecha > b.fecha) {
                    return -1;
                }
                if (a.fecha < b.fecha) {
                    return 1;
                }
                return 0;
            });

            return {
                ...state, 
                listado: gastos_editar
            };

        case GASTOS.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( gasto => gasto.id !== payload )
            };
        
        default:
            return state;
    }
}