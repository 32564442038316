// Alerts
export const ALERT = {
    AGREGAR: 'SET_ALERT',
    ELIMINAR: 'REMOVE_ALERT'
};

// Breadcrumb
export const BREADCRUMB = {
    EDITAR: 'EDIT_BREADCRUMB'
};

// Loader
export const LOADER = {
    MOSTRAR: 'SHOW_LOADER',
    OCULTAR: 'HIDE_LOADER'
};

// Auth
export const AUTH = {
    INICIAR: 'LOGIN',
    ERROR: 'LOGIN_ERROR',
    SESION: 'OBTENER_USUARIO',
    SESION_ERROR: 'OBTENER_USUARIO_ERROR',
    CERRAR: 'LOGOUT',
    RECUPERAR: 'RECUPERAR_USUARIO',
    RESTABLECER: 'RESTABLECER_USUARIO'
};

// Dashboard
export const DASHBOARD = {
    OBTENER: 'GET_DASHBOARD'
};

// Colaboradores
export const COLABORADORES = {
    FILTROS: 'FILTROS_COLABORADORES', 
    BUSCAR: 'BUSCAR_COLABORADORES',
    DETALLES: 'DETALLES_COLABORADOR',
    CONTRATAR: 'CONTRATAR_COLABORADOR',
    ELIMINAR: 'ELIMINAR_COLABORADOR',
    CONTRATACIONES: {
        LISTAR: 'LISTAR_CONTRATACIONES_COLABORADOR'
    }, 
    VACACIONES: {
        LISTAR: 'LISTAR_VACACIONES_COLABORADOR'
    }, 
    INCIDENCIAS: {
        LISTAR: 'LISTAR_INCIDENCIAS_COLABORADOR'
    }, 
    REMOTOS: {
        LISTAR: 'LISTAR_REMOTOS_COLABORADOR'
    }, 
    REPORTES: {
        LISTAR: 'LISTAR_REPORTES_COLABORADOR'
    }, 
    ARCHIVOS: {
        LISTAR: 'LISTAR_ARCHIVOS_COLABORADOR', 
        DETALLES: 'DETALLES_ARCHIVO_COLABORADOR'
    }
};

export const TIPOS_COLABORADORES = {
    FILTROS: 'FILTROS_TIPOS_COLABORADORES', 
    BUSCAR: 'BUSCAR_TIPOS_COLABORADORES', 
    PERMISOS: 'PERMISOS_TIPOS_COLABORADORES', 
    AGREGAR: 'AGREGAR_TIPO_COLABORADOR', 
    DETALLES: 'DETALLES_TIPO_COLABORADOR', 
    ELIMINAR: 'ELIMINAR_TIPO_COLABORADOR'
};


// Punto de venta
export const PUNTO = {
    BUSCAR_PRODUCTOS: 'BUSCAR_PRODUCTOS', 
    BUSCAR_SERVICIOS: 'BUSCAR_SERVICIOS'
};

// Ventas
export const VENTAS = {
    BUSCAR: 'BUSCAR_VENTAS', 
    FILTROS: 'FILTROS_VENTAS', 
    DETALLES: 'DETALLES_VENTA', 
    CANCELAR: 'CANCELAR_VENTA'
};

// Clientes
export const CLIENTES = {
    BUSCAR: 'BUSCAR_CLIENTES', 
    FILTROS: 'FILTROS_CLIENTES', 
    DETALLES: 'DETALLES_CLIENTE', 
    ELIMINAR: 'ELIMINAR_CLIENTE'
};

// Ordenes
export const ORDENES = {
    BUSCAR: 'BUSCAR_ORDENES', 
    FILTROS: 'FILTROS_PRODUCTOS', 
    DETALLES: 'DETALLES_ORDEN', 
    CANCELAR: 'CANCELAR_ORDEN', 
    ELIMINAR: 'ELIMINAR_ORDEN'
};

// Gastos
export const GASTOS = {
    BUSCAR: 'BUSCAR_GASTOS', 
    FILTROS: 'FILTROS_GASTOS', 
    AGREGAR: 'AGREGAR_GASTO', 
    DETALLES: 'DETALLES_GASTO', 
    EDITAR: 'EDITAR_GASTO', 
    ELIMINAR: 'ELIMINAR_GASTO'
};

// Proveedores
export const PROVEEDORES = {
    BUSCAR: 'BUSCAR_PROVEEDORES', 
    DETALLES: 'DETALLES_PROVEEDOR', 
    ELIMINAR: 'ELIMINAR_PROVEEDOR'
};

// Inventario
export const INVENTARIO = {
    BUSCAR: 'BUSCAR_INVENTARIO', 
    DETALLES: 'DETALLES_INVENTARIO', 
    FILTROS: 'FILTROS_INVENTARIO'
};

// Movimientos
export const MOVIMIENTOS = {
    BUSCAR: 'BUSCAR_MOVIMIENTOS',
    BUSCAR_PRODUCTOS: 'BUSCAR_PRODUCTOS'
};

// Solicitudes
export const SOLICITUDES = {
    BUSCAR: 'BUSCAR_SOLICITUDES', 
    FILTROS: 'FILTROS_PRODUCTOS', 
    EVENTOS: 'BUSCAR_EVENTOS', 
    DETALLES: 'DETALLES_SOLICITUD', 
    CANCELAR: 'CANCELAR_SOLICITUD', 
    ELIMINAR: 'ELIMINAR_SOLICITUD'
};

// Calendario
export const CALENDARIO = {
    BUSCAR: 'BUSCAR_CALENDARIO',
    AGREGAR: 'AGREGAR_EVENTO',
    EDITAR: 'EDITAR_EVENTO',
    DETALLES: 'DETALLES_EVENTO',
    ELIMINAR: 'ELIMINAR_EVENTO',
};

// Perfil
export const PERFIL = {
    OBTENER: 'OBTENER_PERFIL', 
    EDITAR: 'EDITAR_PERFIL'
};

// Configuración
export const CONFIGURACION_PRODUCTOS = {
    FILTROS: 'FILTROS_PRODUCTOS', 
    BUSCAR: 'BUSCAR_PRODUCTOS', 
    AGREGAR: 'AGREGAR_PRODUCTO', 
    DETALLES: 'DETALLES_PRODUCTO', 
    EDITAR: 'EDITAR_PRODUCTO', 
    ELIMINAR: 'ELIMINAR_PRODUCTO', 
    ELIMINAR_MULTIPLE: 'ELIMINAR_PRODUCTOS'
};

export const CONFIGURACION_SERVICIOS = {
    FILTROS: 'FILTROS_SERVICIOS', 
    BUSCAR: 'BUSCAR_SERVICIOS', 
    AGREGAR: 'AGREGAR_SERVICIO', 
    DETALLES: 'DETALLES_SERVICIO', 
    EDITAR: 'EDITAR_SERVICIO', 
    ELIMINAR: 'ELIMINAR_SERVICIO'
};

export const CONFIGURACION_UNIDADES = {
    BUSCAR: 'BUSCAR_UNIDADES', 
    AGREGAR: 'AGREGAR_UNIDAD', 
    DETALLES: 'DETALLES_UNIDAD', 
    EDITAR: 'EDITAR_UNIDAD', 
    ELIMINAR: 'ELIMINAR_UNIDAD'
};

export const CONFIGURACION_CATEGORIAS = {
    BUSCAR: 'BUSCAR_CATEGORIAS', 
    AGREGAR: 'AGREGAR_CATEGORIA', 
    DETALLES: 'DETALLES_CATEGORIA', 
    EDITAR: 'EDITAR_CATEGORIA', 
    ELIMINAR: 'ELIMINAR_CATEGORIA'
};

export const CONFIGURACION_SUBCATEGORIAS = {
    BUSCAR: 'BUSCAR_SUBCATEGORIAS', 
    AGREGAR: 'AGREGAR_SUBCATEGORIA', 
    DETALLES: 'DETALLES_SUBCATEGORIA', 
    EDITAR: 'EDITAR_SUBCATEGORIA', 
    ELIMINAR: 'ELIMINAR_SUBCATEGORIA'
};

export const CONFIGURACION_SUBSUBCATEGORIAS = {
    BUSCAR: 'BUSCAR_SUBSUBCATEGORIAS', 
    AGREGAR: 'AGREGAR_SUBSUBCATEGORIA', 
    DETALLES: 'DETALLES_SUBSUBCATEGORIA', 
    EDITAR: 'EDITAR_SUBSUBCATEGORIA', 
    ELIMINAR: 'ELIMINAR_SUBSUBCATEGORIA'
};

export const CONFIGURACION_AREAS = {
    BUSCAR: 'BUSCAR_AREAS', 
    AGREGAR: 'AGREGAR_AREA', 
    DETALLES: 'DETALLES_AREA', 
    EDITAR: 'EDITAR_AREA', 
    ELIMINAR: 'ELIMINAR_AREA'
};

export const CONFIGURACION_ORGANIZADORES = {
    BUSCAR: 'BUSCAR_ORGANIZADORES', 
    AGREGAR: 'AGREGAR_ORGANIZADOR', 
    DETALLES: 'DETALLES_ORGANIZADOR', 
    EDITAR: 'EDITAR_ORGANIZADOR', 
    ELIMINAR: 'ELIMINAR_ORGANIZADOR'
};

export const CONFIGURACION_UBICACIONES = {
    BUSCAR: 'BUSCAR_UBICACIONES', 
    AGREGAR: 'AGREGAR_UBICACION', 
    DETALLES: 'DETALLES_UBICACION', 
    EDITAR: 'EDITAR_UBICACION', 
    ELIMINAR: 'ELIMINAR_UBICACION'
};

export const CONFIGURACION_METODOS = {
    BUSCAR: 'BUSCAR_METODOS', 
    AGREGAR: 'AGREGAR_METODO', 
    DETALLES: 'DETALLES_METODO', 
    EDITAR: 'EDITAR_METODO', 
    ELIMINAR: 'ELIMINAR_METODO'
};