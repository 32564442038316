import { CONFIGURACION_PRODUCTOS, CONFIGURACION_SERVICIOS, CONFIGURACION_UNIDADES, CONFIGURACION_CATEGORIAS, CONFIGURACION_SUBCATEGORIAS, CONFIGURACION_SUBSUBCATEGORIAS, CONFIGURACION_AREAS, CONFIGURACION_ORGANIZADORES, CONFIGURACION_UBICACIONES, CONFIGURACION_METODOS } from '../actions/types';

const initialState = {
    productos: {
        ventana_filtros: false, 
        listado: [],
        detalles: null
    }, 
    servicios: {
        ventana_filtros: false, 
        listado: [],
        detalles: null
    },
    unidades: {
        listado: [],
        detalles: null
    },
    categorias: {
        listado: [],
        detalles: null
    },
    subcategorias: {
        listado: [],
        detalles: null
    },
    subsubcategorias: {
        listado: [],
        detalles: null
    },
    areas: {
        listado: [],
        detalles: null
    }, 
    organizadores: {
        listado: [],
        detalles: null
    }, 
    ubicaciones: {
        listado: [],
        detalles: null
    }, 
    metodos: {
        listado: [],
        detalles: null
    }
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case CONFIGURACION_PRODUCTOS.FILTROS:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    ventana_filtros: payload
                }
            };
            
        case CONFIGURACION_PRODUCTOS.BUSCAR:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    listado: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.AGREGAR:
            let productos_agregar = state.productos.listado;

            productos_agregar = [
                ...productos_agregar, payload
            ];

            productos_agregar = productos_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_agregar
                }
            };

        case CONFIGURACION_PRODUCTOS.DETALLES:
            return {
                ...state,
                productos: {
                    ...state.productos, 
                    detalles: payload
                }
            };

        case CONFIGURACION_PRODUCTOS.EDITAR:
            let productos_editar = state.productos.listado;

            productos_editar = productos_editar.map(producto => producto.id === payload.id ? payload : producto);

            productos_editar = productos_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: productos_editar
                }
            };

        case CONFIGURACION_PRODUCTOS.ELIMINAR:
            return {
                ...state, 
                productos: {
                    ...state.productos, 
                    listado: state.productos.listado.filter( producto => producto.id !== payload.id )
                }
            };

        case CONFIGURACION_PRODUCTOS.ELIMINAR_MULTIPLE:
            return {
                ...state,
                productos: {
                    ...state.productos,
                    listado: state.productos.listado.filter(
                        producto => !payload.map(id => String(id)).includes(String(producto.id))
                    )
                }
            };
    
        case CONFIGURACION_SERVICIOS.FILTROS:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    ventana_filtros: payload
                }
            };
            
        case CONFIGURACION_SERVICIOS.BUSCAR:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    listado: payload
                }
            };

        case CONFIGURACION_SERVICIOS.AGREGAR:
            let servicios_agregar = state.servicios.listado;

            servicios_agregar = [
                ...servicios_agregar, payload
            ];

            servicios_agregar = servicios_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_agregar
                }
            };

        case CONFIGURACION_SERVICIOS.DETALLES:
            return {
                ...state,
                servicios: {
                    ...state.servicios, 
                    detalles: payload
                }
            };

        case CONFIGURACION_SERVICIOS.EDITAR:
            let servicios_editar = state.servicios.listado;

            servicios_editar = servicios_editar.map(servicio => servicio.id === payload.id ? payload : servicio);

            servicios_editar = servicios_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: servicios_editar
                }
            };

        case CONFIGURACION_SERVICIOS.ELIMINAR:
            return {
                ...state, 
                servicios: {
                    ...state.servicios, 
                    listado: state.servicios.listado.filter( servicio => servicio.id !== payload.id )
                }
            };

        case CONFIGURACION_UNIDADES.BUSCAR:
            return {
                ...state,
                unidades: {
                    ...state.unidades, 
                    listado: payload
                }
            };

        case CONFIGURACION_UNIDADES.AGREGAR:
            let unidades_agregar = state.unidades.listado;

            unidades_agregar = [
                ...unidades_agregar, payload
            ];

            unidades_agregar = unidades_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                unidades: {
                    ...state.unidades, 
                    listado: unidades_agregar
                }
            };

        case CONFIGURACION_UNIDADES.DETALLES:
            return {
                ...state,
                unidades: {
                    ...state.unidades, 
                    detalles: payload
                }
            };

        case CONFIGURACION_UNIDADES.EDITAR:
            let unidades_editar = state.unidades.listado;

            unidades_editar = unidades_editar.map(unidad => unidad.id === payload.id ? payload : unidad);

            unidades_editar = unidades_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                unidades: {
                    ...state.unidades, 
                    listado: unidades_editar
                }
            };

        case CONFIGURACION_UNIDADES.ELIMINAR:
            return {
                ...state, 
                unidades: {
                    ...state.unidades, 
                    listado: state.unidades.listado.filter( unidad => unidad.id !== payload.id )
                }
            };      
        
        case CONFIGURACION_CATEGORIAS.BUSCAR:
            return {
                ...state,
                categorias: {
                    ...state.categorias, 
                    listado: payload
                }
            };
        
        case CONFIGURACION_CATEGORIAS.AGREGAR:
            let categorias_agregar = state.categorias.listado;
        
            categorias_agregar = [
                ...categorias_agregar, payload
            ];
        
            categorias_agregar = categorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: categorias_agregar
                }
            };
        
        case CONFIGURACION_CATEGORIAS.DETALLES:
            return {
                ...state,
                categorias: {
                    ...state.categorias, 
                    detalles: payload
                }
            };
        
        case CONFIGURACION_CATEGORIAS.EDITAR:
            let categorias_editar = state.categorias.listado;
        
            categorias_editar = categorias_editar.map(categoria => categoria.id === payload.id ? payload : categoria);
        
            categorias_editar = categorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: categorias_editar
                }
            };
        
        case CONFIGURACION_CATEGORIAS.ELIMINAR:
            return {
                ...state, 
                categorias: {
                    ...state.categorias, 
                    listado: state.categorias.listado.filter( categoria => categoria.id !== payload.id )
                }
            };

        case CONFIGURACION_SUBCATEGORIAS.BUSCAR:
            return {
                ...state,
                subcategorias: {
                    ...state.subcategorias, 
                    listado: payload
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.AGREGAR:
            let subcategorias_agregar = state.subcategorias.listado;
        
            subcategorias_agregar = [
                ...subcategorias_agregar, payload
            ];
        
            subcategorias_agregar = subcategorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: subcategorias_agregar
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.DETALLES:
            return {
                ...state,
                subcategorias: {
                    ...state.subcategorias, 
                    detalles: payload
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.EDITAR:
            let subcategorias_editar = state.subcategorias.listado;
        
            subcategorias_editar = subcategorias_editar.map(subcategoria => subcategoria.id === payload.id ? payload : subcategoria);
        
            subcategorias_editar = subcategorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: subcategorias_editar
                }
            };
        
        case CONFIGURACION_SUBCATEGORIAS.ELIMINAR:
            return {
                ...state, 
                subcategorias: {
                    ...state.subcategorias, 
                    listado: state.subcategorias.listado.filter( subcategoria => subcategoria.id !== payload.id )
                }
            };

        case CONFIGURACION_SUBSUBCATEGORIAS.BUSCAR:
            return {
                ...state,
                subsubcategorias: {
                    ...state.subsubcategorias, 
                    listado: payload
                }
            };
        
        case CONFIGURACION_SUBSUBCATEGORIAS.AGREGAR:
            let subsubcategorias_agregar = state.subsubcategorias.listado;
        
            subsubcategorias_agregar = [
                ...subsubcategorias_agregar, payload
            ];
        
            subsubcategorias_agregar = subsubcategorias_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subsubcategorias: {
                    ...state.subsubcategorias, 
                    listado: subsubcategorias_agregar
                }
            };
        
        case CONFIGURACION_SUBSUBCATEGORIAS.DETALLES:
            return {
                ...state,
                subsubcategorias: {
                    ...state.subsubcategorias, 
                    detalles: payload
                }
            };
        
        case CONFIGURACION_SUBSUBCATEGORIAS.EDITAR:
            let subsubcategorias_editar = state.subsubcategorias.listado;
        
            subsubcategorias_editar = subsubcategorias_editar.map(subsubcategoria => subsubcategoria.id === payload.id ? payload : subsubcategoria);
        
            subsubcategorias_editar = subsubcategorias_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });
        
            return {
                ...state, 
                subsubcategorias: {
                    ...state.subsubcategorias, 
                    listado: subsubcategorias_editar
                }
            };
        
        case CONFIGURACION_SUBSUBCATEGORIAS.ELIMINAR:
            return {
                ...state, 
                subsubcategorias: {
                    ...state.subsubcategorias, 
                    listado: state.subsubcategorias.listado.filter( subsubcategoria => subsubcategoria.id !== payload.id )
                }
            };

        case CONFIGURACION_AREAS.BUSCAR:
            return {
                ...state,
                areas: {
                    ...state.areas, 
                    listado: payload
                }
            };

        case CONFIGURACION_AREAS.AGREGAR:
            let areas_agregar = state.areas.listado;

            areas_agregar = [
                ...areas_agregar, payload
            ];

            areas_agregar = areas_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: areas_agregar
                }
            };

        case CONFIGURACION_AREAS.DETALLES:
            return {
                ...state,
                areas: {
                    ...state.areas, 
                    detalles: payload
                }
            };

        case CONFIGURACION_AREAS.EDITAR:
            let areas_editar = state.areas.listado;

            areas_editar = areas_editar.map(area => area.id === payload.id ? payload : area);

            areas_editar = areas_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: areas_editar
                }
            };

        case CONFIGURACION_AREAS.ELIMINAR:
            return {
                ...state, 
                areas: {
                    ...state.areas, 
                    listado: state.areas.listado.filter( area => area.id !== payload.id )
                }
            };

        case CONFIGURACION_ORGANIZADORES.BUSCAR:
            return {
                ...state,
                organizadores: {
                    ...state.organizadores, 
                    listado: payload
                }
            };

        case CONFIGURACION_ORGANIZADORES.AGREGAR:
            let organizadores_agregar = state.organizadores.listado;

            organizadores_agregar = [
                ...organizadores_agregar, payload
            ];

            organizadores_agregar = organizadores_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                organizadores: {
                    ...state.organizadores, 
                    listado: organizadores_agregar
                }
            };

        case CONFIGURACION_ORGANIZADORES.DETALLES:
            return {
                ...state,
                organizadores: {
                    ...state.organizadores, 
                    detalles: payload
                }
            };

        case CONFIGURACION_ORGANIZADORES.EDITAR:
            let organizadores_editar = state.organizadores.listado;

            organizadores_editar = organizadores_editar.map(organizador => organizador.id === payload.id ? payload : organizador);

            organizadores_editar = organizadores_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                organizadores: {
                    ...state.organizadores, 
                    listado: organizadores_editar
                }
            };

        case CONFIGURACION_ORGANIZADORES.ELIMINAR:
            return {
                ...state, 
                organizadores: {
                    ...state.organizadores, 
                    listado: state.organizadores.listado.filter( organizador => organizador.id !== payload.id )
                }
            };
        
        case CONFIGURACION_UBICACIONES.BUSCAR:
            return {
                ...state,
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: payload
                }
            };

        case CONFIGURACION_UBICACIONES.AGREGAR:
            let ubicaciones_agregar = state.ubicaciones.listado;

            ubicaciones_agregar = [
                ...ubicaciones_agregar, payload
            ];

            ubicaciones_agregar = ubicaciones_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: ubicaciones_agregar
                }
            };

        case CONFIGURACION_UBICACIONES.DETALLES:
            return {
                ...state,
                ubicaciones: {
                    ...state.ubicaciones, 
                    detalles: payload
                }
            };

        case CONFIGURACION_UBICACIONES.EDITAR:
            let ubicaciones_editar = state.ubicaciones.listado;

            ubicaciones_editar = ubicaciones_editar.map(ubicacion => ubicacion.id === payload.id ? payload : ubicacion);

            ubicaciones_editar = ubicaciones_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: ubicaciones_editar
                }
            };

        case CONFIGURACION_UBICACIONES.ELIMINAR:
            return {
                ...state, 
                ubicaciones: {
                    ...state.ubicaciones, 
                    listado: state.ubicaciones.listado.filter( ubicacion => ubicacion.id !== payload.id )
                }
            };

        case CONFIGURACION_METODOS.BUSCAR:
            return {
                ...state,
                metodos: {
                    ...state.metodos, 
                    listado: payload
                }
            };

        case CONFIGURACION_METODOS.AGREGAR:
            let metodos_agregar = state.metodos.listado;

            metodos_agregar = [
                ...metodos_agregar, payload
            ];

            metodos_agregar = metodos_agregar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                metodos: {
                    ...state.metodos, 
                    listado: metodos_agregar
                }
            };

        case CONFIGURACION_METODOS.DETALLES:
            return {
                ...state,
                metodos: {
                    ...state.metodos, 
                    detalles: payload
                }
            };

        case CONFIGURACION_METODOS.EDITAR:
            let metodos_editar = state.metodos.listado;

            metodos_editar = metodos_editar.map(metodo => metodo.id === payload.id ? payload : metodo);

            metodos_editar = metodos_editar.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                    return 1;
                }
                if (a.nombre < b.nombre) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                metodos: {
                    ...state.metodos, 
                    listado: metodos_editar
                }
            };

        case CONFIGURACION_METODOS.ELIMINAR:
            return {
                ...state, 
                metodos: {
                    ...state.metodos, 
                    listado: state.metodos.listado.filter( metodo => metodo.id !== payload.id )
                }
            };
            
        default:
            return state;
    }
}