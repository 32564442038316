import axios from 'axios';

import { VENTAS } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const filtrarVentas = (opcion) => dispatch => {
    dispatch({
        type: VENTAS.FILTROS,
        payload: opcion
    });
}

export const buscarVentas = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/ventas/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: VENTAS.BUSCAR,
            payload: res.data.ventas
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerVenta = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/ventas/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: VENTAS.DETALLES,
            payload: res.data.venta
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarVenta = () => dispatch => {
    dispatch({
        type: VENTAS.DETALLES,
        payload: null
    });
}

export const cancelarVenta = (venta) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id: venta.id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/ventas/cancelar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: VENTAS.CANCELAR,
            payload: {
                ...venta, 
                status: 1
            }
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}