import axios from 'axios';

import { CONFIGURACION_PRODUCTOS, CONFIGURACION_SERVICIOS, CONFIGURACION_UNIDADES, CONFIGURACION_CATEGORIAS, CONFIGURACION_SUBCATEGORIAS, CONFIGURACION_SUBSUBCATEGORIAS, CONFIGURACION_AREAS, CONFIGURACION_ORGANIZADORES, CONFIGURACION_UBICACIONES, CONFIGURACION_METODOS } from '../actions/types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

//Productos
export const filtarProductosConfiguracion = (opcion) => dispatch => {
    dispatch({
        type: CONFIGURACION_PRODUCTOS.FILTROS,
        payload: opcion
    });
}

export const buscarProductosConfiguracion = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_productos`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.BUSCAR,
            payload: res.data.productos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearProductoConfiguracion = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(producto);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_producto`, body, config);

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.AGREGAR,
            payload: res.data.producto
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerProductoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_producto`, body, config);

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.DETALLES,
            payload: res.data.producto
        });
    } catch (error){         
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarProductoConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_PRODUCTOS.DETALLES,
        payload: null
    });
}

export const editarProductoConfiguracion = (producto) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(producto);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_producto`, body, config);

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.EDITAR,
            payload: res.data.producto
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarProductoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_producto`, body, config);

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarProductosConfiguracion = (ids) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        ids
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_productos`, body, config);

        await dispatch({
            type: CONFIGURACION_PRODUCTOS.ELIMINAR_MULTIPLE,
            payload: ids
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Servicios
export const filtarServiciosConfiguracion = (opcion) => dispatch => {
    dispatch({
        type: CONFIGURACION_SERVICIOS.FILTROS,
        payload: opcion
    });
}

export const buscarServiciosConfiguracion = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_servicios`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SERVICIOS.BUSCAR,
            payload: res.data.servicios
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearServicioConfiguracion = (servicio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(servicio);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_servicio`, body, config);

        await dispatch({
            type: CONFIGURACION_SERVICIOS.AGREGAR,
            payload: res.data.servicio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerServicioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_servicio`, body, config);

        await dispatch({
            type: CONFIGURACION_SERVICIOS.DETALLES,
            payload: res.data.servicio
        });
    } catch (error){         
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarServicioConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_SERVICIOS.DETALLES,
        payload: null
    });
}

export const editarServicioConfiguracion = (servicio) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(servicio);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_servicio`, body, config);

        await dispatch({
            type: CONFIGURACION_SERVICIOS.EDITAR,
            payload: res.data.servicio
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarServicioConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_servicio`, body, config);

        await dispatch({
            type: CONFIGURACION_SERVICIOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Unidades
export const buscarUnidadesConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_unidades`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UNIDADES.BUSCAR,
            payload: res.data.unidades
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearUnidadConfiguracion = (unidad) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(unidad);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_unidad`, body, config);

        await dispatch({
            type: CONFIGURACION_UNIDADES.AGREGAR,
            payload: res.data.unidad
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerUnidadConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_unidad`, body, config);

        await dispatch({
            type: CONFIGURACION_UNIDADES.DETALLES,
            payload: res.data.unidad
        });
    } catch (error){         
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarUnidadConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_UNIDADES.DETALLES,
        payload: null
    });
}

export const editarUnidadConfiguracion = (unidad) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(unidad);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_unidad`, body, config);

        await dispatch({
            type: CONFIGURACION_UNIDADES.EDITAR,
            payload: res.data.unidad
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarUnidadConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_unidad`, body, config);

        await dispatch({
            type: CONFIGURACION_UNIDADES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Categorias
export const buscarCategoriasConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_categorias`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS.BUSCAR,
            payload: res.data.categorias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearCategoriaConfiguracion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS.AGREGAR,
            payload: res.data.categoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerCategoriaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS.DETALLES,
            payload: res.data.categoria
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarCategoriaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_CATEGORIAS.DETALLES,
        payload: null
    });
}

export const editarCategoriaConfiguracion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS.EDITAR,
            payload: res.data.categoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarCategoriaConfiguracion = (categoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(categoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_categoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_CATEGORIAS.ELIMINAR,
            payload: categoria
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Subcategorias
export const buscarSubcategoriasConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_subcategorias`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBCATEGORIAS.BUSCAR,
            payload: res.data.subcategorias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearSubcategoriaConfiguracion = (subcategoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(subcategoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_subcategoria`, body, config);
        await dispatch(changeLoader(false));

        if(subcategoria.categoria){
            await dispatch({
                type: CONFIGURACION_SUBCATEGORIAS.AGREGAR,
                payload: res.data.subcategoria
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSubcategoriaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_subcategoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBCATEGORIAS.DETALLES,
            payload: res.data.subcategoria
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarSubcategoriaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_SUBCATEGORIAS.DETALLES,
        payload: null
    });
}

export const editarSubcategoriaConfiguracion = (subcategoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(subcategoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_subcategoria`, body, config);
        await dispatch(changeLoader(false));

        if(subcategoria.categoria){
            await dispatch({
                type: CONFIGURACION_SUBCATEGORIAS.EDITAR,
                payload: res.data.subcategoria
            });
        }else{
            await dispatch({
                type: CONFIGURACION_SUBCATEGORIAS.ELIMINAR,
                payload: subcategoria
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarSubcategoriaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_subcategoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBCATEGORIAS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Subsubcategorias
export const buscarSubsubcategoriasConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_subsubcategorias`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBSUBCATEGORIAS.BUSCAR,
            payload: res.data.subsubcategorias
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearSubsubcategoriaConfiguracion = (subsubcategoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(subsubcategoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_subsubcategoria`, body, config);
        await dispatch(changeLoader(false));

        if(subsubcategoria.subcategoria){
            await dispatch({
                type: CONFIGURACION_SUBSUBCATEGORIAS.AGREGAR,
                payload: res.data.subsubcategoria
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerSubsubcategoriaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_subsubcategoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBSUBCATEGORIAS.DETALLES,
            payload: res.data.subsubcategoria
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarSubsubcategoriaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_SUBSUBCATEGORIAS.DETALLES,
        payload: null
    });
}

export const editarSubsubcategoriaConfiguracion = (subsubcategoria) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(subsubcategoria);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_subsubcategoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBSUBCATEGORIAS.EDITAR,
            payload: res.data.subsubcategoria
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarSubsubcategoriaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_subsubcategoria`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_SUBSUBCATEGORIAS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Areas
export const buscarAreasConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_areas`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AREAS.BUSCAR,
            payload: res.data.areas
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearAreaConfiguracion = (area) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(area);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_area`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AREAS.AGREGAR,
            payload: res.data.area
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerAreaConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_area`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AREAS.DETALLES,
            payload: res.data.area
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarAreaConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_AREAS.DETALLES,
        payload: null
    });
}

export const editarAreaConfiguracion = (area) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(area);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_area`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AREAS.EDITAR,
            payload: res.data.area
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarAreaConfiguracion = (area) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(area);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_area`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_AREAS.ELIMINAR,
            payload: area
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Organizadores
export const buscarOrganizadoresConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_organizadores`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ORGANIZADORES.BUSCAR,
            payload: res.data.organizadores
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearOrganizadorConfiguracion = (organizador) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(organizador);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_organizador`, body, config);
        await dispatch(changeLoader(false));

        if(organizador.area){
            await dispatch({
                type: CONFIGURACION_ORGANIZADORES.AGREGAR,
                payload: res.data.organizador
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerOrganizadorConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_organizador`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ORGANIZADORES.DETALLES,
            payload: res.data.organizador
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarOrganizadorConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_ORGANIZADORES.DETALLES,
        payload: null
    });
}

export const editarOrganizadorConfiguracion = (organizador) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(organizador);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_organizador`, body, config);
        await dispatch(changeLoader(false));

        if(organizador.area){
            await dispatch({
                type: CONFIGURACION_ORGANIZADORES.EDITAR,
                payload: res.data.organizador
            });
        }else{
            await dispatch({
                type: CONFIGURACION_ORGANIZADORES.ELIMINAR,
                payload: organizador
            });
        }

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarOrganizadorConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_organizador`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_ORGANIZADORES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Ubicaciones
export const buscarUbicacionesConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_ubicaciones`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UBICACIONES.BUSCAR,
            payload: res.data.ubicaciones
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearUbicacionConfiguracion = (ubicacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(ubicacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_ubicacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UBICACIONES.AGREGAR,
            payload: res.data.ubicacion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerUbicacionConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_ubicacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UBICACIONES.DETALLES,
            payload: res.data.ubicacion
        });
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarUbicacionConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_UBICACIONES.DETALLES,
        payload: null
    });
}

export const editarUbicacionConfiguracion = (ubicacion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(ubicacion);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_ubicacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UBICACIONES.EDITAR,
            payload: res.data.ubicacion
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarUbicacionConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_ubicacion`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_UBICACIONES.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

//Metodos
export const buscarMetodosConfiguracion = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/configuracion/listar_metodos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURACION_METODOS.BUSCAR,
            payload: res.data.metodos
        });
    } catch (error){ 
        await await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearMetodoConfiguracion = (metodo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(metodo);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/crear_metodo`, body, config);

        await dispatch({
            type: CONFIGURACION_METODOS.AGREGAR,
            payload: res.data.metodo
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerMetodoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/detalles_metodo`, body, config);

        await dispatch({
            type: CONFIGURACION_METODOS.DETALLES,
            payload: res.data.metodo
        });
    } catch (error){         
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarMetodoConfiguracion = () => dispatch => {
    dispatch({
        type: CONFIGURACION_METODOS.DETALLES,
        payload: null
    });
}

export const editarMetodoConfiguracion = (metodo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(metodo);

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/editar_metodo`, body, config);

        await dispatch({
            type: CONFIGURACION_METODOS.EDITAR,
            payload: res.data.metodo
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarMetodoConfiguracion = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuracion/eliminar_metodo`, body, config);

        await dispatch({
            type: CONFIGURACION_METODOS.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}