import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { cancelarOrden } from '../../../actions/ordenes';

import { formatNumber } from '../../../utils/generalFunctions';

const StatusOrden = ({ orden, reestablecerOrden }) => {

    const dispatch =  useDispatch();

    const [cancelarModal, setCancelarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Status orden */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(cancelarOrden(orden));
        await handleCerrar(null);
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerOrden(null);
            setCancelarModal(false);
        }
    }

    useEffect(() => {
        if(orden != null){
            setCancelarModal(true);
        }
    }, [orden]);

    return (
        <Modal show={cancelarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Status de la órden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Qué deseas hacer con la órden a "{orden?.proveedor?.alias}" por un total de {formatNumber(orden?.monto, 2, '$', '')}?</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ? (
                        <button type="button" className="btn btn-danger" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Cancelarla</button>
                    ) : (
                        <button type="button" className="btn btn-danger" onClick={() => handleProcesar()}><i className="fas fa-times fa-sm"></i> Cancelarla</button>
                    )
                }
                <Link className="btn btn-success px-2 float-end" to={`/egresos/ordenes/aceptar/${orden?.id}`} disabled={statusProcesar}><i className="fas fa-check"></i> Aceptarla</Link>
            </Modal.Footer>
        </Modal>
    )
}

export default StatusOrden;