import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarOrdenes } from '../../../actions/ordenes';

import { formatNumber } from '../../../utils/generalFunctions';

import StatusOrden from './StatusOrden';
import EliminarOrden from './EliminarOrden';

const Ordenes = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Ordenes',
            path: '/colaboradores/tipos'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.ordenes);

    const [tipos, setTipos] = useState([]);

    const [ordenStatus, setOrdenStatus] = useState(null);
    const [ordenEliminar, setOrdenEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Proveedor',
            selector: row => row.proveedor?.alias || 'Sin definir',
        },
        {
            name: 'Fecha',
            grow: 1.5,
            cell: item => item.fecha && item.fecha != '0000-00-00' ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'
        },
        {
            name: 'Método de pago',
            selector: row => row.metodo?.nombre || 'Sin definir',
        },
        {
            name: 'Productos',
            selector: row => formatNumber(row.productos, 0, '', '')
        },
        {
            name: 'Total a pagar',
            selector: row => formatNumber(row.monto, 2, '$ ', '')
        },
        {
            name: 'Status',
            selector: (row, index) => {
                switch(row.status){
                    case 1:
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleStatusOrden(row)}><Badge pill bg="secondary">Pendiente</Badge></button></h5>
                        )
                        
                    case 2:
                        return(
                            <h5><Badge pill bg="success">Finalizada</Badge></h5>
                        )

                    case 3:
                        return(
                            <h5><Badge pill bg="danger">Rechazada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                if(row.status == 1){
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarOrden(row)}><i className="fas fa-trash text-danger"></i></button>
                            <Link className="btn btn-sm btn-link px-2 float-end" to={`/egresos/ordenes/editar/${row.id}`}><i className="fas fa-edit text-warning"></i></Link>
                        </div>
                    )
                }else{
                    return(
                        <div className="text-right w-100">
                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarOrden(row)}><i className="fas fa-trash text-danger"></i></button>
                        </div>
                    )
                }
            }
        }
    ];

    /* Status orden */
    const handleStatusOrden = (orden) => {
        setOrdenStatus(orden);
    }

    /* Eliminar orden */
    const handleEliminarOrden = (tipo) => {
        setOrdenEliminar(tipo);
    }

    useEffect(() => {
        dispatch(buscarOrdenes());
    }, []);

    useEffect(() => {
        setTipos(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <StatusOrden orden={ordenStatus} reestablecerOrden={handleStatusOrden} />
            <EliminarOrden orden={ordenEliminar} reestablecerOrden={handleEliminarOrden} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Ordenes</h3>

                    <div className="d-flex justify-content-end">
                        <Link to="/egresos/ordenes/nueva">
                            <button className="btn btn-primary">Agregar orden</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={tipos}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Ordenes;