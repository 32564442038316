import { VENTAS } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case VENTAS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case VENTAS.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case VENTAS.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case VENTAS.CANCELAR:
            return {
                ...state,
                listado: state.listado.map(
                    venta => venta.id == payload.id ? payload : venta
                )
            };
        
        default:
            return state;
    }
}