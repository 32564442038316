import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { setAlert } from '../../../actions/alert';
import { crearProductoConfiguracion, buscarUnidadesConfiguracion, buscarCategoriasConfiguracion, buscarSubcategoriasConfiguracion, buscarSubsubcategoriasConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const NuevoProducto = ({ producto, reestablecerProducto }) => {

    const dispatch =  useDispatch();

    const { listado: listado_unidades } = useSelector(state => state.configuracion.unidades);
    const { listado: listado_categorias } = useSelector(state => state.configuracion.categorias);
    const { listado: listado_subcategorias } = useSelector(state => state.configuracion.subcategorias);
    const { listado: listado_subsubcategorias } = useSelector(state => state.configuracion.subsubcategorias);

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [productoData, setProductoData] = useState({
        id_categoria: null,  
        id_subcategoria: null,  
        id_subsubcategoria: null,  
        nombre: '', 
        fabricante: '', 
        reorden: '', 
        codigo_barras: '', 
        clave_alternativa: '', 
        precio_compra: '', 
        precio_venta: '', 
        descripcion: '', 
        unidades: []
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [subcategoriaData, setSubcategoriaData] = useState(null);
    const [subcategoriasFilter, setSubcategoriasFilter] = useState([]);

    const [subsubcategoriaData, setSubsubcategoriaData] = useState(null);
    const [subsubcategoriasFilter, setSubsubcategoriasFilter] = useState([]);

    const [tiposData, setTiposData] = useState([]);
    const tiposFilter = [
        { value: 1, label: 'Inventario' }, 
        { value: 2, label: 'Uso' }, 
        { value: 3, label: 'Venta' }
    ];

    const [unidadesData, setUnidadesData] = useState([]);
    const [unidadesFilter, setUnidadesFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Crear producto */
    const handleAbrir = () => {
        dispatch(buscarUnidadesConfiguracion());
        dispatch(buscarCategoriasConfiguracion());
    }

    const handleInputChange = ({ target }) => {
        setProductoData({
            ...productoData,
            [target.name]: target.value
        });
    }

    const handleSelectCategoria = (option) => {
        setProductoData({
            ...productoData,
            id_categoria: option ? option.value : null, 
            id_subcategoria: null
        });

        setCategoriaData(option);
        setSubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubcategoriasConfiguracion(option?.value));
        }else{
            setSubcategoriasFilter([]);
        }
    }

    const handleSelectSubcategoria = (option) => {
        setProductoData({
            ...productoData,
            id_subcategoria: option ? option.value : null, 
            id_subsubcategoria: null
        });

        setSubcategoriaData(option);
        setSubsubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubsubcategoriasConfiguracion(option?.value));
        }else{
            setSubsubcategoriasFilter([]);
        }
    }

    const handleSelectSubsubcategoria = (option) => {
        setProductoData({
            ...productoData,
            id_subsubcategoria: option ? option.value : null
        });

        setSubsubcategoriaData(option);
    }

    /* Unidades */
    const handleAgregarUnidad = () => {
        let unidades = productoData.unidades;

        unidades.push({
            id_unidad: '',
            tipo: '',
            cantidad: ''
        });

        let ids_unidades = unidadesData;
        let tipos = tiposData;

        ids_unidades.push(null);
        tipos.push(null);

        setUnidadesData(ids_unidades);
        setTiposData(tipos);

        setProductoData({ 
            ...productoData, 
            unidades
        });
    }

    const handleCantidadChange = ({ target }) => {
        let nombre_separado = target.name.split('cantidad_');

        let posicion = nombre_separado[1];
        
        let unidades = productoData.unidades;

        unidades[posicion].cantidad = target.value || '';

        setProductoData({
            ...productoData,
            unidades
        });
    }

    const handleSelectTipo = (option, position) => {
        let unidades = productoData.unidades;
        let tipos = tiposData;

        unidades[position].tipo = option ? option.value : '';
        tipos[position] = option;

        setTiposData(tipos);

        setProductoData({
            ...productoData,
            unidades
        });
    }

    const handleSelectUnidad = (option, position) => {
        let unidades = productoData.unidades;
        let ids_unidades = unidadesData;

        unidades[position].id_unidad = option ? option.value : '';
        ids_unidades[position] = option;

        setUnidadesData(ids_unidades);

        setProductoData({
            ...productoData,
            unidades
        });
    }

    const handleFilasUnidades = (unidades) => {

        let unidades_campos = [];

        if(unidades){
            for (let x = 0; x < unidades?.length; x++) {
                unidades_campos.push(
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group h-100">
                            <div className="form-floating h-100">
                                <Select
                                    name="tipo"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder={'Tipo de unidad ' + (x + 1)}
                                    options={tiposFilter}
                                    value={tiposData[x]}
                                    isClearable={true}
                                    onChange={(value) => handleSelectTipo(value, x)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                            <div className="form-floating h-100">
                                <Select
                                    name="unidad"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder={'Unidad ' + (x + 1)}
                                    options={unidadesFilter}
                                    value={unidadesData[x]}
                                    isClearable={true}
                                    onChange={(value) => handleSelectUnidad(value, x)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                            <div className="form-floating">
                                <input type="number" className="form-control" placeholder="Cantidad" id={'cantidad_' + x} name={'cantidad_' + x} value={unidades[x].cantidad || ''} onChange={e => handleCantidadChange(e)}/>
                                <label htmlFor={'cantidad_' + x}>Cantidad {x + 1}</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-danger delete" id={'eliminarContacto_' + x} onClick={e => handleEliminarUnidad(e)}><i className="fa-solid fa-xmark"></i></button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return unidades_campos;
    }

    const handleEliminarUnidad = async ({ currentTarget }) => {
        let id_separado = currentTarget.id.split('_');    

        let unidades = productoData.unidades;
        let tipos = tiposData;
        let ids_unidades = unidadesData;

        unidades.splice(id_separado[1], 1);
        tipos.splice(id_separado[1], 1);
        ids_unidades.splice(id_separado[1], 1);

        setProductoData({ 
            ...productoData, 
            unidades
        });

        setTiposData(tipos);
        setUnidadesData(ids_unidades);
    }
    
    const handleProcesar = async () => {
        if(productoData.nombre && productoData.precio_venta && productoData.unidades.length > 0){
            await setStatusProcesar(true);
            await dispatch(crearProductoConfiguracion(productoData));
            await handleCerrar();
            await setStatusProcesar(false);
        }else{
            await dispatch(setAlert('El nombre, precio de venta y al menos una unidad son requeridos', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setProductoData({
                id_categoria: null,  
                id_subcategoria: null,  
                id_subsubcategoria: null,  
                nombre: '', 
                fabricante: '', 
                reorden: '', 
                codigo_barras: '', 
                clave_alternativa: '', 
                precio_compra: '', 
                precio_venta: '', 
                descripcion: '', 
                unidades: []
            });

            setTiposData([]);
            setUnidadesData([]);

            setCategoriaData(null);
            setSubcategoriaData(null);
            setSubsubcategoriaData(null);

            setNuevoModal(false);
            reestablecerProducto(null);
        }
    }

    useEffect(() => {
        if(producto){
            setNuevoModal(true);

            setProductoData({
                ...productoData
            });
        }        
    }, [producto]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_unidades.length; i++) {
            options.push({ value: listado_unidades[i].id, label: listado_unidades[i].nombre });
        }

        setUnidadesFilter(options);
    }, [listado_unidades]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_categorias.length; i++) {
            options.push({ value: listado_categorias[i].id, label: listado_categorias[i].nombre });
        }

        setCategoriasFilter(options);
    }, [listado_categorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subcategorias.length; i++) {
            options.push({ value: listado_subcategorias[i].id, label: listado_subcategorias[i].nombre });
        }

        setSubcategoriasFilter(options);
    }, [listado_subcategorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subsubcategorias.length; i++) {
            options.push({ value: listado_subsubcategorias[i].id, label: listado_subsubcategorias[i].nombre });
        }

        setSubsubcategoriasFilter(options);
    }, [listado_subsubcategorias]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            <Modal show={nuevoModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-9 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={productoData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Fabricante" id="fabricante" name="fabricante" value={productoData.fabricante} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="fabricante">Fabricante</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_categoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Categoría"
                                    options={categoriasFilter}
                                    value={categoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectCategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_subcategoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Subcategoría"
                                    options={subcategoriasFilter}
                                    value={subcategoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectSubcategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group h-100">
                                <Select
                                    name="id_subsubcategoria"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Subsubcategoría"
                                    options={subsubcategoriasFilter}
                                    value={subsubcategoriaData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectSubsubcategoria(value)}
                                    menuPortalTarget={document.body}
                                    menuPlacement="auto"
                                    components={{
                                        ValueContainer: CustomValueContainer
                                    }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="number" className="form-control" placeholder="Punto de reorden" id="reorden" name="reorden" value={productoData.reorden} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="reorden">Punto de reorden</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Código de barras" id="codigo_barras" name="codigo_barras" value={productoData.codigo_barras} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="codigo_barras">Código de barras</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Clave alternativa" id="clave_alternativa" name="clave_alternativa" value={productoData.clave_alternativa} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="clave_alternativa">Clave alternativa</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Precio de compra" id="precio_compra" name="precio_compra" value={productoData.precio_compra} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="precio_compra">Precio de compra</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Precio de venta" id="precio_venta" name="precio_venta" value={productoData.precio_venta} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="precio_venta">Precio de venta</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group mb-2">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={productoData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>

                        { handleFilasUnidades(productoData.unidades) }
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusProcesar ?
                            <>
                                <button type="button" className="btn btn-primary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Unidad</button>
                                <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                            </>
                        :
                            <>
                                <button type="button" className="btn btn-primary" onClick={() => handleAgregarUnidad()}><i className="fas fa-plus fa-sm"></i> Unidad</button>
                                <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default NuevoProducto;