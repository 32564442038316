import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { filtarProductos } from '../../../actions/ordenes';
import { buscarInventario } from '../../../actions/inventario';
import { buscarCategoriasConfiguracion, buscarSubcategoriasConfiguracion, buscarSubsubcategoriasConfiguracion } from '../../../actions/configuracion';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const FiltrosProducto = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.ordenes);
    const { listado: listado_categorias } = useSelector(state => state.configuracion.categorias);
    const { listado: listado_subcategorias } = useSelector(state => state.configuracion.subcategorias);
    const { listado: listado_subsubcategorias } = useSelector(state => state.configuracion.subsubcategorias);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        nombre: null,
        codigo_barras: null,
        categoria: null,
        subcategoria: null,
        subsubcategoria: null, 
        status: 0
    });

    const [categoriaData, setCategoriaData] = useState(null);
    const [categoriasFilter, setCategoriasFilter] = useState([]);

    const [subcategoriaData, setSubcategoriaData] = useState(null);
    const [subcategoriasFilter, setSubcategoriasFilter] = useState([]);

    const [subsubcategoriaData, setSubsubcategoriaData] = useState(null);
    const [subsubcategoriasFilter, setSubsubcategoriasFilter] = useState([]);

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Cualquiera' }, 
        { value: 1, label: 'Con existencias' }, 
        { value: 2, label: 'Sin existencias' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Filtros */
    const handleAbrir = () => {
        dispatch(buscarCategoriasConfiguracion({
            nombre: null
        }));
    }

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }

    const handleSelectCategoria = (option) => {
        setFiltrosData({
            ...filtrosData,
            categoria: option ? option.value : null, 
            subcategoria: null
        });

        setCategoriaData(option);
        setSubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubcategoriasConfiguracion(option?.value));
        }else{
            setSubcategoriasFilter([]);
        }
    }

    const handleSelectSubcategoria = (option) => {
        setFiltrosData({
            ...filtrosData,
            subcategoria: option ? option.value : null, 
            subsubcategoria: null
        });

        setSubcategoriaData(option);
        setSubsubcategoriaData(null);

        if(option?.value){
            dispatch(buscarSubsubcategoriasConfiguracion(option?.value));
        }else{
            setSubsubcategoriasFilter([]);
        }
    }

    const handleSelectSubsubcategoria = (option) => {
        setFiltrosData({
            ...filtrosData,
            subsubcategoria: option ? option.value : null
        });

        setSubsubcategoriaData(option);
    }

    const handleSelectStatus = (option) => {
        setFiltrosData({
            ...filtrosData,
            status: option ? option.value : null
        });

        setStatusData(option);
    }
    
    const handleProcesar = async (limpiar) => {
        await setStatusProcesar(true);

        if(!limpiar){
            await dispatch(buscarInventario(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null,
                codigo_barras: null,
                categoria: null,
                subcategoria: null,
                subsubcategoria: null, 
                status: 0
            };

            await dispatch(buscarInventario(datos_base));

            await setFiltrosData(datos_base);

            await setCategoriaData(null);
            await setSubcategoriaData(null);
            await setSubsubcategoriaData(null);
        }

        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtarProductos(false));
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_categorias?.length; i++) {
            options.push({ value: listado_categorias[i].id, label: listado_categorias[i].nombre });
        }

        setCategoriasFilter(options);
    }, [listado_categorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subcategorias.length; i++) {
            options.push({ value: listado_subcategorias[i].id, label: listado_subcategorias[i].nombre });
        }

        setSubcategoriasFilter(options);
    }, [listado_subcategorias]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_subsubcategorias.length; i++) {
            options.push({ value: listado_subsubcategorias[i].id, label: listado_subsubcategorias[i].nombre });
        }

        setSubsubcategoriasFilter(options);
    }, [listado_subsubcategorias]);

    return (
        <Modal show={ventana_filtros} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar productos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Código de barras" id="codigo_barras" name="codigo_barras" value={filtrosData.codigo_barras} onChange={e => handleInputChange(e)} />
                                <label htmlFor="codigo_barras">Código de barras</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="categoria"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Categoría"
                                options={categoriasFilter}
                                value={categoriaData}
                                isClearable={true}
                                onChange={(value) => handleSelectCategoria(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="subcategoria"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Subcategoría"
                                options={subcategoriasFilter}
                                value={subcategoriaData}
                                isClearable={true}
                                onChange={(value) => handleSelectSubcategoria(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="subsubcategoria"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Subsubcategoría"
                                options={subsubcategoriasFilter}
                                value={subsubcategoriaData}
                                isClearable={true}
                                onChange={(value) => handleSelectSubsubcategoria(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Status"
                                options={statusFilter}
                                value={statusData}
                                isClearable={true}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-secondary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => handleProcesar(true)}><i className="fas fa-trash fa-sm"></i> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosProducto;