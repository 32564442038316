import React, { useState, useEffect } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { limpiarVenta, obtenerVenta } from '../../../actions/ventas';

import { formatNumber } from '../../../utils/generalFunctions';

const DetallesVenta = ({ venta, reestablecerVenta }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.ventas);

    const [detallesModal, setDetallesModal] = useState(false);

    const [ventaData, setVentaData] = useState({
        id: null,
        cliente: null, 
        metodo: null, 
        fecha: null, 
        status: 0, 
        total: 0, 
        productos: [], 
        servicios: []
    });

    const columnsProductos = [
        {
            name: 'Producto',
            cell: row => row.producto?.nombre || 'Sin definir',
            grow: 2
        }, 
        {
            name: 'Cantidad',
            cell: row => formatNumber(row.cantidad, 0, '', '')
        }, 
        {
            name: 'Unidad',
            cell: row => row.unidad?.nombre || 'Sin definir'
        }
    ];

    const columnsServicios = [
        {
            name: 'Servicio',
            cell: row => row.servicio?.nombre || 'Sin definir',
            grow: 3
        },
        {
            name: 'Precio',
            cell: row => formatNumber(row.precio, 2, '$ ', '')
        }
    ];

    /* Detalles venta */
    const handleAbrir = () => {
        dispatch(limpiarVenta());
    }

    const handleCerrar = () => {
        setVentaData({
            id: null,
            concepto: '', 
            id_proveedor: null,  
            id_metodo: null,  
            monto: 0
        });

        setDetallesModal(false);
        reestablecerVenta(null);
    }

    useEffect(() => {
        if(venta){
            setDetallesModal(true);

            dispatch(obtenerVenta(venta?.id));
        }        
    }, [venta]);

    useEffect(() => {
        if(detalles){
            if(!ventaData.id){
                setVentaData({
                    ...ventaData,
                    id: detalles?.id, 
                    cliente: detalles?.cliente, 
                    metodo: detalles?.metodo, 
                    fecha: detalles?.fecha, 
                    status: detalles?.status, 
                    total: detalles?.total,
                    productos: detalles?.productos, 
                    servicios: detalles?.servicios
                });
            }
        }
    }, [detalles]);

    return (
        <Modal show={detallesModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Información de la venta</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-3">
                        <label className="fw-bold">Cliente</label>
                        <p>{ventaData?.cliente?.nombre ? ventaData?.cliente?.nombre + ' ' + ventaData?.cliente?.apellido : 'Público general'}</p>
                    </div>

                    <div className="col-md-2">
                        <label className="fw-bold">Método de pago</label>
                        <p>{ventaData?.metodo?.nombre || 'Sin definir'}</p>
                    </div>

                    <div className="col-md-3">
                        <label className="fw-bold">Fecha</label>
                        <p>{ventaData?.fecha && ventaData?.fecha != '0000-00-00' ? new Date(ventaData?.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(ventaData?.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                    </div>

                    <div className="col-md-2">
                        <label className="fw-bold">Status</label>
                        <p><h5><Badge pill bg={ventaData?.status ? 'danger' : 'success'}>{ventaData?.status ? 'Cancelada' : 'Realizada'}</Badge></h5></p>
                    </div>

                    <div className="col-md-2">
                        <label className="fw-bold">Total</label>
                        <p>{formatNumber((ventaData?.total || 0), 2, '$ ', '')}</p>
                    </div>
                </div>

                {
                    ventaData.productos?.length > 0 &&
                        <div className="row">
                            <DataTable 
                                columns={columnsProductos}
                                data={ventaData.productos}
                                highlightOnHover={true}
                            />
                        </div>
                }

                <hr />

                {
                    ventaData.servicios?.length > 0 &&
                        <div className="row mt-3">
                            <DataTable 
                                columns={columnsServicios}
                                data={ventaData.servicios}
                                highlightOnHover={true}
                            />
                        </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default DetallesVenta;