import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Alert from './components/layout/Alert';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Dashboard';

// Colaboradores
import Colaboradores from './views/colaboradores/colaboradores/Colaboradores';
import NuevoColaborador from './views/colaboradores/colaboradores/NuevoColaborador';
import EditarColaborador from './views/colaboradores/colaboradores/EditarColaborador';
import PerfilColaborador from './views/colaboradores/colaboradores/PerfilColaborador';

import TiposColaboradores from './views/colaboradores/tipos/Tipos';
import NuevoTipoColaborador from './views/colaboradores/tipos/NuevoTipo';
import EditarTipoColaborador from './views/colaboradores/tipos/EditarTipo';

// Punto de venta
import Punto from './views/ingresos/punto/Punto';

// Ventas
import Ventas from './views/ingresos/ventas/Ventas';

// Clientes
import Clientes from './views/ingresos/clientes/Clientes';

// Ordenes
import Ordenes from './views/egresos/ordenes/Ordenes';
import NuevaOrden from './views/egresos/ordenes/NuevaOrden';
import EditarOrden from './views/egresos/ordenes/EditarOrden';
import AceptarOrden from './views/egresos/ordenes/AceptarOrden';

// Gastos
import Gastos from './views/egresos/gastos/Gastos';

// Proveedores
import Proveedores from './views/egresos/proveedores/Proveedores';
import NuevoProveedor from './views/egresos/proveedores/NuevoProveedor';
import EditarProveedor from './views/egresos/proveedores/EditarProveedor';

// Inventario
import Inventario from './views/inventario/inventario/Inventario';

// Movimientos
import Movimientos from './views/inventario/movimientos/Movimientos';
import NuevoMovimiento from './views/inventario/movimientos/NuevoMovimiento';

// Solicitudes
import Solicitudes from './views/inventario/solicitudes/Solicitudes';
import NuevaSolicitud from './views/inventario/solicitudes/NuevaSolicitud';
import EditarSolicitud from './views/inventario/solicitudes/EditarSolicitud';
import AceptarSolicitud from './views/inventario/solicitudes/AceptarSolicitud';

// Calendario
import Calendario from './views/calendario/Calendario';

// Reportes
import Reportes from './views/reportes/Reportes';

// Configuración
import Productos from './views/configuracion/productos/Productos';
import Servicios from './views/configuracion/servicios/Servicios';
import Unidades from './views/configuracion/unidades/Unidades';
import Categorias from './views/configuracion/categorias/Categorias';
import Subcategorias from './views/configuracion/categorias/subcategorias/Subcategorias';
import Subsubcategorias from './views/configuracion/categorias/subcategorias/subsubcategorias/Subsubcategorias';
import Areas from './views/configuracion/areas/Areas';
import Organizadores from './views/configuracion/areas/organizadores/Organizadores';
import Ubicaciones from './views/configuracion/areas/organizadores/ubicaciones/Ubicaciones';
import Metodos from './views/configuracion/metodos/Metodos';
import Perfil from './views/perfil/Perfil';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout></Layout>
					<section className="container-fluid principal-section">
						<Alert></Alert>

						<Routes>
							{/* Login */}
							<Route exact path="/" element={<ValidateSession><Login /></ValidateSession>}/>
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Dashboard */}
							<Route path="/dashboard" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Colaboradores */}
							<Route path="/colaboradores/colaboradores" element={<PrivateRoute routePermission='colaboradores.ver'><Colaboradores /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/nuevo" element={<PrivateRoute routePermission='colaboradores.agregar'><NuevoColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/editar/:id" element={<PrivateRoute routePermission='colaboradores.editar'><EditarColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/colaboradores/perfil/:id" element={<PrivateRoute routePermission='colaboradores.perfil'><PerfilColaborador /></PrivateRoute>}/>

							<Route path="/colaboradores/tipos" element={<PrivateRoute routePermission='tipos_colaboradores.ver'><TiposColaboradores /></PrivateRoute>}/>
							<Route path="/colaboradores/tipos/nuevo" element={<PrivateRoute routePermission='tipos_colaboradores.agregar'><NuevoTipoColaborador /></PrivateRoute>}/>
							<Route path="/colaboradores/tipos/editar/:id" element={<PrivateRoute routePermission='tipos_colaboradores.editar'><EditarTipoColaborador /></PrivateRoute>}/>

							{/* Ingresos */}
							<Route path="/ingresos/punto" element={<PrivateRoute routePermission='punto_de_venta.ver'><Punto /></PrivateRoute>}/>

							<Route path="/ingresos/ventas" element={<PrivateRoute routePermission='ventas.ver'><Ventas /></PrivateRoute>}/>

							<Route path="/ingresos/cotizador" element={<PrivateRoute routePermission='cotizador.ver'><Dashboard /></PrivateRoute>}/>

							<Route path="/ingresos/clientes" element={<PrivateRoute routePermission='clientes.ver'><Clientes /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/nueva" element={<PrivateRoute routePermission='clientes.agregar'><Dashboard /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/editar/:id" element={<PrivateRoute routePermission='clientes.editar'><Dashboard /></PrivateRoute>}/>
							<Route path="/ingresos/clientes/perfil/:id" element={<PrivateRoute routePermission='clientes.perfil'><Dashboard /></PrivateRoute>}/>

							{/* Egresos */}
							<Route path="/egresos/ordenes" element={<PrivateRoute routePermission='ordenes.ver'><Ordenes /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/nueva" element={<PrivateRoute routePermission='ordenes.agregar'><NuevaOrden /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/editar/:id" element={<PrivateRoute routePermission='ordenes.editar'><EditarOrden /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/aceptar/:id" element={<PrivateRoute routePermission='ordenes.aceptar'><AceptarOrden /></PrivateRoute>}/>
							<Route path="/egresos/ordenes/detalles/:id" element={<PrivateRoute routePermission='ordenes.detalles'><Dashboard /></PrivateRoute>}/>

							<Route path="/egresos/gastos" element={<PrivateRoute routePermission='gastos.ver'><Gastos /></PrivateRoute>}/>

							<Route path="/egresos/proveedores" element={<PrivateRoute routePermission='proveedores.ver'><Proveedores /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/nuevo" element={<PrivateRoute routePermission='proveedores.agregar'><NuevoProveedor /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/editar/:id" element={<PrivateRoute routePermission='proveedores.editar'><EditarProveedor /></PrivateRoute>}/>
							<Route path="/egresos/proveedores/perfil/:id" element={<PrivateRoute routePermission='proveedores.perfil'><Dashboard /></PrivateRoute>}/>

							{/* Inventario */}
							<Route path="/inventario" element={<PrivateRoute routePermission='inventario.ver'><Inventario /></PrivateRoute>}/>

							{/* Movimientos */}
							<Route path="/movimientos" element={<PrivateRoute routePermission='movimientos.ver'><Movimientos /></PrivateRoute>}/>
							<Route path="/movimientos/nuevo" element={<PrivateRoute routePermission='movimientos.agregar'><NuevoMovimiento /></PrivateRoute>}/>

							{/* Solciitudes */}
							<Route path="/solicitudes" element={<PrivateRoute routePermission='solicitudes.ver'><Solicitudes /></PrivateRoute>}/>
							<Route path="/solicitudes/nueva" element={<PrivateRoute routePermission='solicitudes.agregar'><NuevaSolicitud /></PrivateRoute>}/>
							<Route path="/solicitudes/editar/:id" element={<PrivateRoute routePermission='solicitudes.editar'><EditarSolicitud /></PrivateRoute>}/>
							<Route path="/solicitudes/aceptar/:id" element={<PrivateRoute routePermission='solicitudes.aceptar'><AceptarSolicitud /></PrivateRoute>}/>
							
							{/* Calendario */}
							<Route path="/calendario" element={<PrivateRoute routePermission='calendario.ver'><Calendario /></PrivateRoute>}/>

							{/* Reportes */}
							<Route path="/reportes" element={<PrivateRoute routePermission='reportes.ver'><Dashboard /></PrivateRoute>}/>

							{/* Perfil */}
							<Route path="/perfil" element={<PrivateRoute routePermission='perfil.ver'><Perfil /></PrivateRoute>}/>

							{/* Configuración */}
							<Route path="/configuracion/productos" element={<PrivateRoute routePermission='productos.ver'><Productos /></PrivateRoute>}/>
							<Route path="/configuracion/servicios" element={<PrivateRoute routePermission='servicios.ver'><Servicios /></PrivateRoute>}/>
							<Route path="/configuracion/unidades" element={<PrivateRoute routePermission='unidades.ver'><Unidades /></PrivateRoute>}/>
							<Route path="/configuracion/categorias" element={<PrivateRoute routePermission='categorias.ver'><Categorias /></PrivateRoute>}/>
							<Route path="/configuracion/categorias/subcategorias/:id" element={<PrivateRoute routePermission='categorias.subcategorias.ver'><Subcategorias /></PrivateRoute>}/>
							<Route path="/configuracion/categorias/subcategorias/:id/subsubcategorias/:id_subcategoria" element={<PrivateRoute routePermission='categorias.subcategorias.subsubcategorias.ver'><Subsubcategorias /></PrivateRoute>}/>
							<Route path="/configuracion/areas" element={<PrivateRoute routePermission='areas.ver'><Areas /></PrivateRoute>}/>
							<Route path="/configuracion/areas/organizadores/:id" element={<PrivateRoute routePermission='areas.organizadores.ver'><Organizadores /></PrivateRoute>}/>
							<Route path="/configuracion/areas/organizadores/:id/ubicaciones/:id_organizador" element={<PrivateRoute routePermission='areas.organizadores.ubicaciones.ver'><Ubicaciones /></PrivateRoute>}/>
							<Route path="/configuracion/metodos" element={<PrivateRoute routePermission='metodos.ver'><Metodos /></PrivateRoute>}/>

							{/* Cuando no matchea con nada*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</section>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;