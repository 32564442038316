import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { eliminarOrden } from '../../../actions/ordenes';

import { formatNumber } from '../../../utils/generalFunctions';

const EliminarOrden = ({ orden, reestablecerOrden }) => {

    const dispatch =  useDispatch();

    const [eliminarModal, setEliminarModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    /* Eliminar orden */
    const handleProcesar = async () => {
        await setStatusProcesar(true);
        await dispatch(eliminarOrden(orden?.id));
        await handleCerrar(null);
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            reestablecerOrden(null);
            setEliminarModal(false);
        }
    }

    useEffect(() => {
        if(orden != null){
            setEliminarModal(true);
        }
    }, [orden]);

    return (
        <Modal show={eliminarModal} backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar órden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Deseas eliminar la órden a "{orden?.proveedor?.alias}" por un total de {formatNumber(orden?.monto, 2, '$ ', '')}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleCerrar()} disabled={statusProcesar}><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcesar ? (
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Si</button>
                    ) : (
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Si</button>
                    )
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EliminarOrden;