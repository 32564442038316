import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { buscarTiposColaboradores } from '../../../actions/tipos_colaboradores';
import { buscarColaboradores, filtarColaboradores } from '../../../actions/colaboradores';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const FiltrosColaborador = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.colaboradores);
    const { listado: listado_tipos } = useSelector(state => state.tipos_colaboradores);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        nombre: null,
        apellido: null,
        tipo: null,
        email: null,
        telefono: null,
        status: [5, 10]
    });

    const [tipoData, setTipoData] = useState(null);
    const [tiposFilter, setTiposFilter] = useState([]);

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 10, label: 'Activo' }, 
        { value: 5, label: 'Inactivo' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Filtros */
    const handleAbrir = () => {
        dispatch(buscarTiposColaboradores({
            nombre: null
        }));
    }

    const handleSelectTipo = (option) => {
        setTipoData(option);

        setFiltrosData({
            ...filtrosData,
            tipo: option != null ? option.value : null
        });
    }

    const handleSelectStatus = (option) => {
        setStatusData(option);

        let status = [];

        for (var i = 0; i < option?.length; i++) {
            status.push(option[i].value);
        }

        setFiltrosData({
            ...filtrosData,
            status
        });
    }

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async (limpiar) => {
        await setStatusProcesar(true);

        if(!limpiar){
            await dispatch(buscarColaboradores(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null,
                apellido: null,
                tipo: null,
                email: null,
                telefono: null,
                status: [5, 10]
            };

            await dispatch(buscarColaboradores(datos_base));

            await setFiltrosData(datos_base);

            await setTipoData(null);
            await setStatusData(null);
        }

        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtarColaboradores(false));
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < listado_tipos?.length; i++) {
            options.push({ value: listado_tipos[i].id, label: listado_tipos[i].nombre });
        }

        setTiposFilter(options);
    }, [listado_tipos]);

    return (
        <Modal show={ventana_filtros} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar colaboradores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellidos" id="apellido" name="apellido" value={filtrosData.apellido} onChange={e => handleInputChange(e)} />
                                <label htmlFor="apellido">Apellidos</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="tipo"
                                name="tipo"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar tipo"
                                isClearable
                                options={tiposFilter}
                                value={tipoData}
                                onChange={(value) => handleSelectTipo(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={filtrosData.email} onChange={e => handleInputChange(e)} />
                                <label htmlFor="email">Email</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Teléfono" id="telefono" name="telefono" value={filtrosData.telefono} onChange={e => handleInputChange(e)} />
                                <label htmlFor="telefono">Teléfono</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group contenedor-select">
                            <Select
                                id="status"
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Seleccionar status"
                                isClearable
                                isMulti={true} 
                                options={statusFilter}
                                value={statusData}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-secondary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => handleProcesar(true)}><i className="fas fa-trash fa-sm"></i> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosColaborador;