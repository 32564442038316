import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarServiciosConfiguracion, buscarUnidadesConfiguracion, filtarServiciosConfiguracion } from '../../../actions/configuracion';

import { formatNumber } from '../../../utils/generalFunctions';

import FiltrosServicio from './FiltrosServicio';
import NuevoServicio from './NuevoServicio';
import EditarServicio from './EditarServicio';
import EliminarServicio from './EliminarServicio';

const Servicios = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Servicios',
            path: '/configuracion/servicios'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.configuracion.servicios);

    const [servicios, setServicios] = useState([]);

    const [serviciosEliminar, setServiciosEliminar] = useState([]);

    const [servicioAgregar, setServicioAgregar] = useState(null);
    const [servicioEditar, setServicioEditar] = useState(null);
    const [servicioDetalles, setServicioDetalles] = useState(null);
    const [servicioEliminar, setServicioEliminar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            grow: 2
        },
        {
            name: 'Categoría',
            selector: row => row.categoria?.nombre || 'Sin definir'
        },
        {
            name: 'Subcategoría',
            selector: row => row.subcategoria?.nombre || 'Sin definir'
        },
        {
            name: 'Subsubcategoría',
            selector: row => row.subsubcategoria?.nombre || 'Sin definir'
        },
        {
            name: 'Precio',
            selector: row => formatNumber(row.precio_venta, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEliminarServicio(row)}><i className="fas fa-trash text-danger"></i></button>
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEditarServicio(row)}><i className="fas fa-edit text-warning"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtarServiciosConfiguracion(true));
    }

    /* Crear servicio */
    const handleAgregarServicio = (servicio) => {
        setServicioEditar(null);
        setServicioEliminar(null);

        setServicioAgregar(servicio);        
    }

    /* Editar servicio */
    const handleEditarServicio = (servicio) => {
        setServicioAgregar(null);
        setServicioEliminar(null);

        setServicioEditar(servicio);
    }

    /* Eliminar servicio */
    const handleEliminarServicio = (servicio) => {
        setServicioAgregar(null);
        setServicioEditar(null);

        setServicioEliminar(servicio);
    }

    useEffect(() => {
        dispatch(buscarUnidadesConfiguracion());
        dispatch(buscarServiciosConfiguracion({
            nombre: null, 
            categoria: null, 
            subcategoria: null, 
            subsubcategoria: null, 
            codigo_barras: null
        }));
    }, []);

    useEffect(() => {
        setServicios(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosServicio />
            <NuevoServicio servicio={servicioAgregar} reestablecerServicio={handleAgregarServicio} />
            <EditarServicio servicio={servicioEditar} reestablecerServicio={handleEditarServicio} />
            <EliminarServicio servicio={servicioEliminar} reestablecerServicio={handleEliminarServicio} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Servicios</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <button type="button" className="btn btn-primary mx-1" onClick={() => handleAgregarServicio({ })}>Agregar servicio</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={servicios}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Servicios;