import { MOVIMIENTOS } from '../actions/types';

const initialState = {
    listado: [], 
    listado_productos: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case MOVIMIENTOS.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case MOVIMIENTOS.BUSCAR_PRODUCTOS:
            return {
                ...state,
                listado_productos: payload
            };
        
        default:
            return state;
    }
}