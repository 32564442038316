import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { editarBreadcrumb } from '../../../actions/breadcrumb';
import { buscarVentas, filtrarVentas } from '../../../actions/ventas';

import { formatNumber } from '../../../utils/generalFunctions';

import FiltrosVenta from './FiltrosVenta';
import DetallesVenta from './DetallesVenta';
import CancelarVenta from './CancelarVenta';

const Ventas = () => {

    let rutasBread = [
        {
            activo: true,
            nombre: 'Ventas',
            path: '/ingresos/ventas'
        }
    ];

    const dispatch =  useDispatch();

    const { listado } = useSelector(state => state.ventas);

    const [ventas, setVentas] = useState([]);

    const [ventaDetalles, setVentaDetalles] = useState(null);
    const [ventaCancelar, setVentaCancelar] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Fólio',
            selector: row => row.folio
        },
        {
            name: 'Cliente',
            selector: row => row.cliente?.nombre ? row.cliente?.nombre + ' ' + row.cliente?.apellido : 'Público general',
            grow: 2
        },
        {
            name: 'Método de pago',
            selector: row => row.metodo?.nombre || 'Sin definir',
            grow: 1.5
        },
        {
            name: 'Fecha',
            grow: 2,
            cell: item => item.fecha && item.fecha != '0000-00-00' ? new Date(item.fecha).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.fecha).toLocaleTimeString('es-MX', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'
        },
        {
            name: 'Total',
            selector: row => formatNumber(row.total, 2, '$ ', '')
        },
        {
            name: 'Status',
            selector: (row, index) => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><button type="button" className="btn btn-link btn-lg p-0" onClick={() => handleCancelarVenta(row)}><Badge pill bg="success">Realizada</Badge></button></h5>
                        )

                    case 1:
                        return(
                            <h5><Badge pill bg="danger">Cancelada</Badge></h5>
                        )
                    default:
                        break;
                }
            }
        },
        {
            name: '',
            cell: row => {
                return(
                    <div className="text-right w-100">
                        <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDetallesVenta(row)}><i className="fas fa-eye text-primary"></i></button>
                    </div>
                )
            }
        }
    ];

    /* Filtros de busqueda */
    const handleFiltrosBusqueda = () => {
        dispatch(filtrarVentas(true));
    }

    /* Detalles venta */
    const handleDetallesVenta = (venta) => {
        setVentaCancelar(null);

        setVentaDetalles(venta);
    }

    /* Cancelar venta */
    const handleCancelarVenta = (venta) => {
        setVentaDetalles(null);

        setVentaCancelar(venta);
    }

    useEffect(() => {
        dispatch(buscarVentas({
            cliente: null, 
            metodo: null, 
            fecha_inicio: null, 
            fecha_fin: null
        }));
    }, []);

    useEffect(() => {
        setVentas(listado);
    }, [listado]);

    useEffect(() => {
        dispatch(editarBreadcrumb(rutasBread));
    }, [rutasBread]);

    return (
        <Fragment>

            <FiltrosVenta />
            <DetallesVenta venta={ventaDetalles} reestablecerVenta={handleDetallesVenta} />
            <CancelarVenta venta={ventaCancelar} reestablecerVenta={handleCancelarVenta} />

            <div className="row">
                <div className="col-md-12 px-4 pb-4">
                    <h3 className="mb-3 mt-5">Historial de ventas</h3>

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFiltrosBusqueda()}>Filtros de búsqueda</button>
                        <Link to='/ingresos/punto'><button className="btn btn-primary mx-1">Crear venta</button></Link>
                    </div>

                    <div className="mt-3">
                        <DataTable 
                            columns={columns}
                            data={ventas}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Ventas;