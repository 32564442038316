import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { buscarTiposColaboradores, filtarTiposColaboradores } from '../../../actions/tipos_colaboradores';

const FiltrosTipo = () => {

    const dispatch =  useDispatch();

    const { ventana_filtros } = useSelector(state => state.tipos_colaboradores);

    const [statusProcesar, setStatusProcesar] = useState(false);

    const [filtrosData, setFiltrosData] = useState({
        nombre: null
    });

    /* Filtros */
    const handleAbrir = () => {}

    const handleInputChange = ({ target }) => {
        setFiltrosData({
            ...filtrosData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async (limpiar) => {
        await setStatusProcesar(true);

        if(!limpiar){
            await dispatch(buscarTiposColaboradores(filtrosData));
        }else{
            let datos_base = {
                ...filtrosData, 
                nombre: null
            };

            await dispatch(buscarTiposColaboradores(datos_base));

            await setFiltrosData(datos_base);
        }

        await handleCerrar();
        await setStatusProcesar(false);
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            dispatch(filtarTiposColaboradores(false));
        }
    }

    return (
        <Modal show={ventana_filtros} size="md" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar tipos de colaborador</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={filtrosData.nombre} onChange={e => handleInputChange(e)} />
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <>
                            <button type="button" className="btn btn-secondary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Buscar</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => handleProcesar(true)}><i className="fas fa-trash fa-sm"></i> Limpiar filtros</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Buscar</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosTipo;