import { SOLICITUDES } from '../actions/types';

const initialState = {
    listado: [],
    listado_eventos: [],
    detalles: null, 
    ventana_filtros: false
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case SOLICITUDES.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case SOLICITUDES.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        case SOLICITUDES.EVENTOS:
            return {
                ...state,
                listado_eventos: payload
            };

        case SOLICITUDES.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case SOLICITUDES.CANCELAR:
            return {
                ...state,
                listado: state.listado.map(
                    orden => orden.id == payload.id ? payload : orden
                )
            };

        case SOLICITUDES.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( orden => orden.id !== payload )
            };
        
        default:
            return state;
    }
}