import axios from 'axios';

import setAuthToken from '../utils/setAuthToken';

import { setAlert } from './alert';
import { changeLoader } from './loader';

import { AUTH } from './types';

// LOGIN USER
export const login = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify(usuario);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/auth/login`, body, config);
        await dispatch(changeLoader(false));

        dispatch({
            type: AUTH.INICIAR,
            payload: res.data
        });
    } catch (error) {
        await dispatch(changeLoader(false));
        if(error?.response?.data?.msg) {
            dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }

        dispatch({
            type: AUTH.ERROR
        });
    }
}

// LOAD USER
export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/auth/sesion`);

        dispatch({
            type: AUTH.SESION,
            payload: res.data.usuario
        });
    } catch (error){         
        dispatch({
            type: AUTH.SESION_ERROR
        });
    }
}

// LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: AUTH.CERRAR
    });
}