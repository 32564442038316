import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { limpiarProducto, obtenerProducto } from '../../../actions/inventario';

import { formatNumber } from '../../../utils/generalFunctions';

const DetallesProducto = ({ producto, reestablecerProducto }) => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.inventario);

    const [detallesModal, setDetallesModal] = useState(false);

    const [inventario, setInventario] = useState([]);

    const columns = [
        {
            name: 'Área',
            selector: row => row?.area?.nombre || 'Sin definir'
        },
        {
            name: 'Organizador',
            selector: row => row?.organizador?.nombre || 'Sin definir'
        },
        {
            name: 'Ubicación',
            selector: row => row?.ubicacion?.nombre || 'Sin definir'
        },
        {
            name: 'Cantidad',
            selector: row => formatNumber(row?.cantidad, 2, '', '')
        }
    ];

    /* Detalles de producto */
    const handleAbrir = () => {
        dispatch(limpiarProducto());
    }

    const handleCerrar = () => {
        setDetallesModal(false);
        reestablecerProducto(null);
    }

    useEffect(() => {
        if(producto){
            setDetallesModal(true);

            dispatch(obtenerProducto(producto?.id));
        }        
    }, [producto]);

    useEffect(() => {
        if(detalles){
            setInventario(detalles);
        }
    }, [detalles]);

    return (
        <Modal show={detallesModal} size="xl" backdrop="static" centered onEntered={() => handleAbrir()} onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Cantidades existentes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTable 
                    columns={columns}
                    data={inventario}
                    highlightOnHover={true}
                />
            </Modal.Body>
        </Modal>
    )
}

export default DetallesProducto;